<template>
  <div class="hello-ezuikit-js">
    <div :id="id" style="width:100%;height:100%"></div>
  </div>
</template>

<script>
import EZUIKit from 'ezuikit-js'

export default {
  props: {
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 300
    },
    videoUrl: {
    },
    id: {
      type: String,
      default: 'video'
    },
    splitBasis: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      token: '',
      expireTime: '',
      player: null
    }
  },
  computed: {
    url () {
      return this.videoUrl
    }
  },
  created () {
    this.getAccessToken()
  },
  methods: {
    async getAccessToken () {
      const data = {
        appKey: '912a251c29f842fbb7ba53c625546304',
        appSecret: 'd3108e75a12143409eb30f6d0800ad19'
      }
      const { data: result } = await this.$axios.post(`https://open.ys7.com/api/lapp/token/get?appKey=${data.appKey}&appSecret=${data.appSecret}`)
      if (result.code === '200') {
        this.token = result.data.accessToken
        this.expireTime = result.data.expireTime
        this.playerVideo()
      }
    },
    playerVideo () {
      console.log(this.url)
      if (this.splitBasis > 0) {
        this.player = new EZUIKit.EZUIKitPlayer({
          autoplay: true,
          id: this.id,
          accessToken: this.token,
          url: this.url,
          template: '', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          // 视频上方头部控件
          // header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
          // plugin: ['talk'],                       // 加载插件，talk-对讲
          // 视频下方底部控件
          // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
          // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
          // openSoundCallBack: data => console.log("开启声音回调", data),
          // closeSoundCallBack: data => console.log("关闭声音回调", data),
          // startSaveCallBack: data => console.log("开始录像回调", data),
          // stopSaveCallBack: data => console.log("录像回调", data),
          // capturePictureCallBack: data => console.log("截图成功回调", data),
          // fullScreenCallBack: data => console.log("全屏回调", data),
          // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
          width: this.width,
          height: this.height,
          splitBasis: this.splitBasis
        })
      } else {
        this.player = new EZUIKit.EZUIKitPlayer({
          autoplay: true,
          id: this.id,
          accessToken: this.token,
          url: this.url,
          template: '', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          // 视频上方头部控件
          // header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
          // plugin: ['talk'],                       // 加载插件，talk-对讲
          // 视频下方底部控件
          // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
          // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
          // openSoundCallBack: data => console.log("开启声音回调", data),
          // closeSoundCallBack: data => console.log("关闭声音回调", data),
          // startSaveCallBack: data => console.log("开始录像回调", data),
          // stopSaveCallBack: data => console.log("录像回调", data),
          // capturePictureCallBack: data => console.log("截图成功回调", data),
          // fullScreenCallBack: data => console.log("全屏回调", data),
          // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
          width: this.width,
          height: this.height
        })
      }
    }
  }
}
</script>
