<template>
  <el-dialog title="单位详情" v-bind="$attrs" :visible.sync="visible" fullscreen class="unit-info"
             @close="close">
    <el-header class="unit-header">
      <div class="header-menu">
        <el-menu class="el-menu-demo" mode="horizontal" :default-active="activeId">
          <template v-for="(item,i) in menu">
            <el-submenu v-if="item.children" :key="i" :index="item.id" :show-timeout="0" :hide-timeout="0">
              <template slot="title">
                <i :class="item.icon"></i>
                <span slot="title">{{ item.text }}</span>
              </template>
              <template v-for="(subitem,j) in item.children">
                <el-menu-item :key="j" :index="subitem.id" @click="switchComponent(subitem)">
                  <i :class="subitem.icon"></i>
                  <span slot="title">{{ subitem.text }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item v-else :key="i" :index="item.id" @click="switchComponent(item)">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.text }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </el-header>
    <main class="unit-main" v-if="companyId>0">
      <transition name="el-fade-in-linear">
        <component :is="componentName" :companyId="companyId"></component>
      </transition>
    </main>
  </el-dialog>
</template>

<script>
import Base from "./Base"

export default {
  name: "CompanyDetailDialog",
  components: {
    Base,
    NFC: () => import("./NFC"),
    Inspect: () => import("../../../supervise/FireInspect"),
    DutyRecord: () => import("./DutyRecord"),
    DeviceReport: () => import("./DeviceReport"),
    Correct: () => import("../../../supervise/Correct"),
    Water: () => import("./Water"),
    Device: () => import("./Device"),
    AlarmInfo: () => import("./AlarmInfo"),
    Archives: () => import("./Archives")
  },
  data() {
    return {
      cacheCompanyId: 0,
      companyId: 0,
      visible: false, //弹窗可见性
      activeId: "9001",
      componentName: "Base",
      menu: [
        {id: '9001', text: '单位信息', componentName: "Base", icon: 'el-icon-info'},
        {
          id: '9002', text: '日常工作', icon: 'el-icon-date',
          children: [
            {id: '9012', text: 'NFC巡检', componentName: "NFC", icon: 'el-icon-mobile-phone'},
            {id: '9013', text: '防火巡检', componentName: "Inspect", icon: 'el-icon-map-location'},
            {id: '9014', text: '消防控制室值班记录', componentName: "DutyRecord", icon: 'el-icon-s-order'},
            {id: '9015', text: '设施维护保养报告', componentName: "DeviceReport", icon: 'el-icon-document'},
            {id: '9016', text: '责令整改', componentName: "Correct", icon: 'el-icon-time'}]
        },
        {id: '9003', text: '水源信息', componentName: "Water", icon: 'el-icon-s-tools'},
        {id: '9004', text: '物联设备', componentName: "Device", icon: 'el-icon-cpu'},
        {id: '9005', text: '告警信息', componentName: "AlarmInfo", icon: 'el-icon-alarm-clock'},
        {id: '9006', text: '单位档案', componentName: "Archives", icon: 'el-icon-document'}
      ],
    }
  },
  methods: {
    show(companyId) {
      this.visible = true
      if (this.cacheCompanyId !== companyId) {
        //已更换
        this.activeId = "9001"
        this.componentName = "Base"
        this.$nextTick(() => {
          const scrollWrap = document.querySelector('main[class=unit-main]')
          scrollWrap.scrollTo(0, 0)
        })
      }
      this.companyId = companyId
      this.cacheCompanyId = companyId
    },
    close() {
      // this.componentName = "Base"
      this.companyId = 0
    },
    switchComponent({id, componentName}) {
      this.activeId = id
      this.componentName = componentName
    }
  }
}
</script>

<style scoped lang="scss">
.unit-info {
  ::v-deep .el-dialog__header {
    height: 24px;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
    height: calc(100vh - 54px);

    .unit-header {
      background-color: rgba(67, 77, 120, 0.15);
      color: #39b8e6;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      height: 76px !important;

      .header-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60px;
        width: 100%;

        .el-menu {
          height: 100%;
          display: flex;
          justify-content: center;
          background-color: transparent !important;
          border-bottom: none !important;

          .el-menu-item,
          .el-submenu__title {
            height: 100%;
            color: #39b8e6 !important;

            i {
              color: #39b8e6 !important;
            }
          }

          //设置选中el-menu-item时的ff2556样式
          .el-menu-item .is-active,
          .el-submenu__title .is-active {
            background: linear-gradient(
                180deg,
                rgba(60, 192, 241, 0) 0%,
                rgba(60, 192, 241, 0.24) 100%
            ) !important;
            color: #39b8e6 !important;

            i {
              color: #39b8e6 !important;
            }
          }

          //设置鼠标悬停时el-menu-item的样式
          .el-menu-item:hover,
          .el-submenu__title:hover {
            background: linear-gradient(
                180deg,
                rgba(60, 192, 241, 0) 0%,
                rgba(60, 192, 241, 0.24) 100%
            ) !important;
            color: #39b8e6 !important;

            i {
              color: #39b8e6 !important;
            }
          }

          .el-menu-item:focus,
          .el-submenu__title:focus {
            background: linear-gradient(
                180deg,
                rgba(60, 192, 241, 0) 0%,
                rgba(60, 192, 241, 0.24) 100%
            ) !important;
            color: #39b8e6 !important;

            i {
              color: #39b8e6 !important;
            }
          }
        }
      }
    }

    .unit-main {
      height: calc(100% - 76px);
      overflow: scroll;
      padding: 15px 15px 3% 15px;
      box-sizing: border-box;
    }
  }
}
</style>
