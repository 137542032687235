<template>
  <div>
    <div id='myEcharts' ref='chart'></div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {}
  },
  mounted () {
    this.drawEcharts()
  },
  watch: {
    data () {
      this.drawEcharts()
    }
  },
  methods: {
    drawEcharts () {
      // let index = 0
      const colorList = ['#f36c6c', '#e6cf4e', '#20d180', '#0093ff', '#FF8989FF', '#89B4FFFF', '#3CC0F1FF', '#FFD28FFF', '#93FFCCFF', '#F76F01']
      const topName = []
      const unitValue = []
      const deviceValue = []
      let arr = JSON.parse(JSON.stringify(this.data))
      arr = arr.sort((a, b) => {
        return a.equipmentCount - b.equipmentCount
      })
      arr = arr.reverse()
      arr.forEach(item => {
        // if (item.areaName === '贸易广场') {
        //   item.unitCount = 1329
        //   item.equipmentCount = 5197
        // }
        topName.push(item.areaName)
        unitValue.push(item.unitCount)
        deviceValue.push(item.equipmentCount)
      })
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: 110,
          top: 10,
          bottom: 0,
          right: 15
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisPointer: {
            label: {
              show: true,
              margin: 15
            }
          },
          data: topName,
          axisLabel: {
            margin: 90,
            fontSize: 14,
            align: 'left',
            color: '#fff'
            // rich: {
            //   a1: {
            //     color: '#fff',
            //     backgroundColor: colorList[0],
            //     width: 20,
            //     height: 20,
            //     fontSize: 14,
            //     align: 'center',
            //     borderRadius: 2
            //   },
            //   a2: {
            //     color: '#fff',
            //     backgroundColor: colorList[1],
            //     width: 20,
            //     height: 20,
            //     fontSize: 14,
            //     align: 'center',
            //     borderRadius: 2
            //   },
            //   a3: {
            //     color: '#fff',
            //     backgroundColor: colorList[2],
            //     width: 20,
            //     height: 20,
            //     fontSize: 14,
            //     align: 'center',
            //     borderRadius: 2
            //   },
            //   b: {
            //     color: colorList[3],
            //     width: 20,
            //     height: 20,
            //     fontSize: 14,
            //     align: 'center',
            //     borderRadius: 2
            //   }
            // },
            // formatter: (params) => {
            //   if (index === this.data.length) {
            //     index = 0
            //   }
            //   index++
            //   if (index - 1 < 3) {
            //     return ['{a' + index + '|' + index + '}' + '  ' + params].join(
            //       '\n'
            //     )
            //   } else {
            //     return ['{b|' + index + '}' + '  ' + params].join('\n')
            //   }
            // }
          }
        },
        series: [
          {
            z: 2,
            name: '设备',
            type: 'bar',
            barWidth: 20,
            barGap: '10%',
            data: deviceValue.map((item, i) => {
              var itemStyle = {
                color: i > 3 ? colorList[3] : colorList[i]
              }
              return {
                value: item,
                itemStyle: itemStyle
              }
            }),
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              fontSize: 14
            },
            itemStyle: {
              barBorderRadius: [20],
              borderColor: '#409EFF',
              borderWidth: 2

            }
          },
          {
            z: 2,
            name: '单位',
            type: 'bar',
            barWidth: 20,
            barGap: '20%',
            roundCap: true,
            data: unitValue.map((item, i) => {
              var itemStyle = {
                color: i > 3 ? colorList[3] : colorList[i]
              }
              return {
                value: item,
                itemStyle: itemStyle
              }
            }),
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              fontSize: 14
            },
            itemStyle: {
              barBorderRadius: [20],
              borderColor: '#409EFF',
              borderWidth: 2
            }
          }
        ],
        dataZoom: [{
          type: 'slider',
          show: true,
          filterMode: 'empty',
          disabled: false,
          yAxisIndex: 0,
          // top: '25%',
          right: '5%',
          // bottom: '15%',
          width: 6,
          start: 0,
          end: 50,
          handleSize: '0', // 滑动条的 左右2个滑动小块的大小
          handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
          textStyle: {
            color: '#fff'
          },
          fillerColor: '#3C62C0', // 拖动条的颜色
          borderColor: 'none',
          backgroundColor: 'rgba(60, 98, 192, 0.302)',
          showDetail: false // 即拖拽时候是否显示详细数值信息 默认true
        }]
      }
      // 基于准备好的dom，初始化echarts实例
      this.$nextTick(() => {
        const dome = this.$refs.chart
        const myChart = this.$echarts.init(dome)
        // 指定饼图中显示的数据格式
        // 绘制图表
        myChart.setOption(option)
        window.addEventListener('resize', () => { myChart.resize() })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#myEcharts {
  width: 100%;
  height: 100%;
}
</style>
