<template>
  <div class="container" v-loading="loading1" element-loading-text="数据导出中" element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <el-popover placement="right" width="720" v-model="searchShow">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
              <el-form-item label="设备类型">
                <el-select v-model="searchForm.typeId" placeholder="请选择设备类型" size="small" clearable>
                  <el-option v-for="(item,i) in typeList" :key="i" :label="item.typeName" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时间范围">
                <el-date-picker size="small" v-model="searchForm.date" type="daterange" start-placeholder="开始日期"
                                end-placeholder="结束日期" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="设备特征码">
                <el-input v-model="searchForm.equipmentCode" placeholder="请输入设备特征码" size="small"></el-input>
              </el-form-item>
              <el-form-item label="行政区域">
                <el-cascader v-model="areaCodes" size="small" placeholder="请选择" :options="areaList"
                             :props="{ checkStrictly: true }" clearable></el-cascader>
              </el-form-item>
              <el-form-item label="单位名称">
                <el-input v-model="searchForm.unitName" placeholder="请输入单位名称" size="small"></el-input>
              </el-form-item>
            </el-form>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" @click="searchShow = false">取消</el-button>
              <el-button type="primary" size="mini" @click="initTable">查询</el-button>
            </div>
            <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
          </el-popover>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" icon="el-icon-download" @click="exportExcel">导出告警</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="warningList" stripe style="width: 100%" height="100%">
        <el-table-column prop="equipmentTypeName" label="设备类型" show-overflow-tooltip width="120">
        </el-table-column>
        <el-table-column prop="unitName" label="所属单位" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="status" label="状态" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <el-tag type="danger" size="normal" v-show="scope.row.status===0">发生</el-tag>
            <el-tag type="primary" size="normal" v-show="scope.row.status===1">已派警</el-tag>
            <el-tag type="success" size="normal" v-show="scope.row.status===2">已出警</el-tag>
            <el-tag type="info" size="normal" v-show="scope.row.status===-1">已解除</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="alarmTypeName" label="告警类型" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column prop="alarmDate" label="告警时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.alarmDate|timeFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="alarmDate" label="同步状态">
          <template slot-scope="scope">
            <el-tag type="danger" size="small" v-if="scope.row.alarmSn.trim() ===''">未同步</el-tag>
            <el-tag type="success" size="small" v-else>已同步</el-tag>
          </template>
        </el-table-column>
        <!--        <el-table-column prop="errorReason" label="告警原因" show-overflow-tooltip>-->
        <!--        </el-table-column>-->

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="设备同步" placement="top">
              <el-button type="success" size="mini" icon="el-icon-refresh" circle
                         v-if="scope.row.alarmSn.trim() ===''"
                         @click="syncAlarm(scope.row.id)"></el-button>
            </el-tooltip>

<!--            <el-tooltip class="item" effect="dark" content="设备同步" placement="top">-->
<!--              <el-button type="success" size="mini" icon="el-icon-refresh" circle-->
<!--                         v-if="scope.row.alarmSn.trim() ===''"-->
<!--                         v-show="scope.row.syncFlag!==1" @click="syncDevice(scope.row.id)"></el-button>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" content="强制同步" placement="top">-->
<!--              <el-button type="danger" size="mini" icon="el-icon-refresh" circle-->
<!--                         v-show="scope.row.syncFlag===1"></el-button>-->
<!--            </el-tooltip>-->
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                     layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading1:false,
      currentPage: 1,
      total: 0,
      warningList: [],
      typeList: [],
      areaList:[],
      areaCodes:[],
      searchShow:false,
      searchForm: {}
    }
  },
  props:{
    extraParams:Object
  },
  created() {
    this.initTable()
    this.getTypeList()
    this.getAreaList()
  },
  methods: {
    async handleCurrentChange(val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable() {
      const data = {}
      if (this.searchForm.typeId) {
        data.typeId = this.searchForm.typeId
      }
      if (this.searchForm.equipmentCode) {
        data.equipmentCode = this.searchForm.equipmentCode
      }
      if (this.searchForm.date) {
        data.startTime = this.searchForm.date[0]+' 00:00:00'
      }
      if (this.searchForm.date) {
        data.endTime = this.searchForm.date[1]+' 23:59:59'
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      if(!!this.searchForm.unitName){
        data.unitName=this.searchForm.unitName
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      const {data: result} = await this.$axios.get('/alarm/getCurrentAlarm', {params: Object.assign(data,this.extraParams||{})})
      if (result.code === 200) {
        this.warningList = result.data.result
        this.total = result.data.total
      }
      else {
        this.$message.error(result.msg)
      }
    },
    // 查找行政区域
    async getTypeList() {
      const {data: result} = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      }
      else {
        this.$message.error(result.msg)
      }
    },
    // 查找行政区域
    async getAreaList() {
      const {data: result} = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        this.areaList = this.changeKey(result.data)
      }
      else {
        this.$message.error(result.msg)
      }
    },
    changeKey(array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children.length > 0) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              if (subItem.children.length > 0) {
                subItem.children.forEach((subItem2, index) => {
                  [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
                })
              }
            }
          })
        }
      })
      return array
    },
    // 导出Excel
    async exportExcel() {
      this.loading1 = true
      const data = {}
      if (this.searchForm.typeId) {
        data.typeId = this.searchForm.typeId
      }
      if (this.searchForm.equipmentCode) {
        data.equipmentCode = this.searchForm.equipmentCode
      }
      if (this.searchForm.date) {
        data.startTime = this.searchForm.date[0]+' 00:00:00'
      }
      if (this.searchForm.date) {
        data.endTime = this.searchForm.date[1]+' 23:59:59'
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      const result = await this.$axios.post('/util/exportCurrentAlarm', data, {responseType: 'blob'})
      if (result.data.type === 'application/json') {
        this.loading1 = false
        this.$message.error('数据为空,不能导出')
        return
      }
      if (!result.data.message) {
        this.loading1 = false
      }
      else {
        this.loading1 = false
        this.$message.error(result.data.message)
        return
      }
      const blob = new Blob([result.data], {type: 'application/octet-stream'})
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '当前告警.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      }
      else {
        navigator.msSaveBlob(blob, '当前告警.xlsx')
      }
    },
    async syncAlarm(id) {
      try {
        const {data} = await this.$axios.post('/alarm/sync/' + id)

        //前端模拟
        const index = this.warningList.findIndex(v => v.id === id)
        Object.assign(this.warningList[index],{alarmSn: "11111"})

        this.$notify({
          type:"success",
          title: "系统消息",
          message: data.msg === 'success' ? '同步成功' : data.msg
        });
        // this.initTable()
      } catch (e) {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}

.dialog {
  margin: 0;
  padding: 10px;

  ::v-deep .el-dialog__header {
    display: none !important;
  }
}

.demo-table-expand {
  font-size: 0;

  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;

    ::v-deep label {
      color: #99a9bf !important;
    }
  }
}

::v-deep .el-table__expanded-cell {
  padding: 0 50px;
}
</style>
