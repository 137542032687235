<template>
  <div class="container_body">
    <!-- 派警人员列表 -->
    <transition name="el-zoom-in-bottom">
      <div class="people_box" v-show="firePeopleFlag" :style="{'right': rightFlag ? '420px' : '20px'}">
        <div class="iteml_list" style="margin-bottom: 5px"> {{ fireStationName }} 可派警人员</div>
        <div class="item_list" v-for="(item, index) in peopleList" :key="index">
          <div class="item_name"> {{ item.name }}</div>
          <div>
            <el-button type="primary" size="mini" @click="sendAlarm(item.userId)">派警</el-button>
          </div>
        </div>
        <el-button type="danger" @click="firePeopleFlag = false" size="mini"><i></i>关闭</el-button>
      </div>
    </transition>
    <transition name="el-zoom-in-bottom">
      <div class="people_box" v-show="fireFlag" :style="{'right': rightFlag ? '420px' : '20px'}">
        <div class="iteml_list" style="margin-bottom: 5px"> 可派警消防站</div>
        <div class="item_list" v-for="(item, index) in fireList" :key="index">
          <div class="list-box" v-if="item.persons.length>0">
            <div class="item_name"> {{ item.name }}</div>
            <div class="item_name"> {{ item.distance }}km</div>
            <div>
              <el-button type="primary" size="mini" @click="sendAlarm(item.persons[0].userId)">派警</el-button>
            </div>
          </div>
        </div>
        <el-button type="danger" @click="fireFlag = false" size="mini"><i></i>关闭</el-button>
      </div>
    </transition>
    <div class="jk" v-if="showModal">
      <el-button type="danger" @click="showModal = false" size="mini"><i></i>关闭</el-button>
      <HikVision />
    </div>
    <!-- <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="center" :zoom="zoom" @ready="handler"> -->
    <!--地图类型-->
    <!-- <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type> -->
    <!--类型切换-->
    <!-- <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map> -->
    <!-- </baidu-map> -->
    <div class="left-col">
      <div class="chart-row">
        <div class="content">
          <div class="title">
            <span><img src="../../assets/img/gis-icon4.png" alt="" width="15px" height="15px">警情信息统计</span>
          </div>
          <div class="alarm">
            <div class="alarm-content">
              <div class="content-box">
                <div class="alarm-data">
                  <img src="../../assets/img/alarm3.png" alt="">
                  <div class="alarm-data-num">
                    <p><span>{{ alarmData.hasDeal }}</span>条</p>
                    <p>今日已处理</p>
                  </div>
                </div>
                <div class="alarm-data">
                  <img src="../../assets/img/alarm4.png" alt="">
                  <div class="alarm-data-num">
                    <p>人工处理：<span>{{ alarmData.peopleDeal }}</span>条</p>
                    <p>自动处理：<span>{{ alarmData.autoDeal }}</span>条</p>
                  </div>
                </div>
              </div>
              <div class="content-box">
                <div class="alarm-data">
                  <img src="../../assets/img/alarm1.png" alt="">
                  <div class="alarm-data-num">
                    <p>处理中：<span>{{ alarmData.process }}</span>条</p>
                  </div>
                </div>
                <div class="alarm-data">
                  <img src="../../assets/img/alarm2.png" alt="">
                  <div class="alarm-data-num">
                    <p>未处理：<span>{{ alarmData.unProcess }}</span>条</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="alarm-chart">
              <alarmChart2 id="pipeChart1" style="margin:0;height:100%" v-if="alarmData1.length !== 0"
                           :data="alarmData1" :color="['#FFCD00','#43BAFF','#E8610F']"
                           :type="['报警信息','真实火警','故障信息']"
                           @itemClick="handleAlarmChartClick('top',$event)"></alarmChart2>
            </div>
            <div class="alarm-chart">
              <alarmChart2 id="pipeChart1" style="margin:0;height:100%" v-if="alarmData2.length !== 0"
                           :data="alarmData2" :color="['#378CFF','#E9A5FF','#A0A0A0']"
                           :type="['误报信息','维保任务','巡查隐患']"></alarmChart2>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-row mini">
        <div class="content">
          <div class="title">
            <span><img src="../../assets/img/gis-icon2.png" alt="" width="15px" height="15px">消防力量统计</span>
          </div>
          <div class="fire-data">
            <div class="fire" @click="open('water')" v-if="fireData[0]">
              <img src="../../assets/img/gis-round2.png" alt="">
              <span>{{ fireData[0].value }}</span>
              <span>{{ fireData[0].name }}</span>
            </div>
            <div class="fire" @click="open('station')" v-if="fireData[1]">
              <img src="../../assets/img/gis-round1.png" alt="">
              <span>{{ fireData[1].value }}</span>
              <span>{{ fireData[1].name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-row">
        <div class="content">
          <div class="title">
            <span><img src="../../assets/img/gis-icon3.png" alt="" width="15px" height="15px">告警列表</span>
            <span class="alarm-count">当日告警:{{ alarmCount.alarmCount }}</span>
            <span class="alarm-count">已处置:{{ alarmCount.hasDealCount }}</span>
            <span class="alarm-count">火警:{{ alarmCount.fireCount }}</span>
            <span class="alarm-count">误报:{{ alarmCount.errorCount }}</span>
          </div>
          <el-table :data="alarmList" height="100%" @row-click="showAlarm">
            <el-table-column label="单位名" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button type="text" @click.stop="showCompanyDialog( scope.row.unitId)">
                  {{ scope.row.unitName }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="告警设备" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.equipmentTypeName }}
              </template>
            </el-table-column>
            <el-table-column label="告警类型" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                {{ scope.row.alarmTypeName }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="middle-col">
      <div class="map-row">
        <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="center" :zoom="zoom" @ready="handler">
        </baidu-map>
      </div>
      <div class="large-row">
        <div class="content">
          <div class="btn_group">
            <div class="btn" v-show="middleFlag">
              <div class="num" style="color: #F56C6C">{{ alarmTotal }}</div>
              <div class="name">当前告警</div>
            </div>
            <div class="btn" @click="changeIcon(3)">
              <div class="num" style="color: #24b42b">
                <OfflineDeviceTotal :area-code="areaCode" />
              </div>
              <div class="name">离线设备</div>
            </div>
            <div class="btn" @click="changeIcon(6)" v-show="middleFlag">
              <div class="num" style="color: #67C23A">{{ allCount.length === 0 ? 0 : allCount[0].buildingCount }}</div>
              <div class="name">建筑物</div>
            </div>
            <div class="btn" @click="changeIcon(4)" v-show="middleFlag">
              <div class="num" style="color: #E6A23C">{{
                  allCount.length === 0 ? 0 : allCount[0].importantUnitCount
                }}
              </div>
              <div class="name">重点单位</div>
            </div>
            <div class="btn" @click="changeIcon(7)" v-show="middleFlag">
              <div class="num" style="color: #409EFF">{{
                  allCount.length === 0 ? 0 : allCount[0].fireHydrantCount
                }}
              </div>
              <div class="name">水源</div>
            </div>
            <div class="btn" @click="changeIcon(8)" v-show="middleFlag">
              <div class="num" style="color: #C0C4CC">{{ allCount.length === 0 ? 0 : allCount[0].videoCount }}</div>
              <div class="name">摄像头</div>
            </div>
            <div class="btn" @click="changeIcon(5)" v-show="middleFlag">
              <div class="num">{{ allCount.length === 0 ? 0 : allCount[0].nineSmallUnitCount }}</div>
              <div class="name">九小场所</div>
            </div>
            <el-dropdown class="btn" v-show="middleFlag" trigger="click" @command="changMapType">
              <div style="width: 100%;">
                <div class="num" style="color: #C0C4CC">{{ options.length }}</div>
                <div class="name" style="color: #fff">地图样式</div>
              </div>
              <el-dropdown-menu slot="dropdown" placement="top">
                <el-dropdown-item icon="el-icon-s-open" :command="item.value" v-for="(item, index) in options"
                                  :key="index"> {{ item.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="right-col">
      <div class="chart-row small">
        <div class="content">
          <div class="title">
            <span><img src="../../assets/img/gis-icon1.png" alt="" width="15px" height="15px">单位分类统计图</span>
          </div>
          <pipeChart id="pipeChart1" :data="unitTypeData" v-if="unitTypeData.length !== 0" :flag="'unit'"></pipeChart>
        </div>
      </div>
      <div class="chart-row">
        <div class="content">
          <div class="title">
            <span><img src="../../assets/img/gis-icon5.png" alt="" width="15px" height="15px">辖区接入统计</span>
          </div>
          <rankingChart id="pipeChart1" :data="rankingData" v-if="rankingData.length !== 0"></rankingChart>
        </div>
      </div>
    </div>
    <div class="area">
      <span style="font-weight: bold;font-size: 12px">当前位置：</span>
      <el-button style="font-weight: bold" type="text" size="mini" icon="el-icon-map-location" @click="open('area')">
        {{ areaName }}
      </el-button>
    </div>
    <div class="dialog1">
      <el-dialog :title="acitveName" v-if="listAShow" :visible.sync="listAShow" width="70%" top="0"
                 :close-on-click-modal="false" @close="listAShow = false">
        <map-list :name="acitveName" :mapStyle="mapStyle" :areaCode="areaCode"></map-list>
      </el-dialog>
      <el-dialog :title="acitveName" v-if="listBShow" :visible.sync="listBShow" width="70%" top="0"
                 :close-on-click-modal="false" @close="listBShow = false">
        <map-list2 :name="acitveName" :mapStyle="mapStyle" :areaCode="areaCode"></map-list2>
      </el-dialog>
    </div>
    <!-- 十户联动 -->
    <el-dialog title="十户联动" class="group-dialog" v-if="groupShow" :visible.sync="groupShow" top="2%" center
               :close-on-click-modal="false" width="60%" v-dialogDrag>
      <!-- <div class="video_box" v-for="(item,i) in videoList" :key="i">
        <div class="video_title">
          <div>{{i+1}}.单位:{{item.unitName}}</div>
          <div>负责人:{{item.legalName}}</div>
          <div>联系电话:{{item.legalPhone}}</div>
          <div>
            <el-button type="primary" size="mini" @click="open('device',item.unitId)">查看设备</el-button>
          </div>
        </div>
        <div class="video_content">
          <div class="video_player" v-for="(urlItem,j) in item.playUrl" :key="j">
            <camera :url="urlItem">
            </camera>
          </div>
        </div>
      </div>
      <span class="dialogs-footer">
        <el-button type="primary" @click="alarmVideo">紧急救援</el-button>
      </span> -->
      <div class="list-button">
        <el-button type="primary" size="small" @click="skipPage(1)">消防通道</el-button>
        <el-button type="primary" size="small" @click="skipPage(2)">消控室</el-button>
      </div>
      <div class="monitor-container">
        <div class="camera-list">
          <div class="list-box">
            <div class="list-wrapper" v-for="(item,i) in cameraList" :key="i" @click="changeCamera(item.cameraCode)">
              <div class="wrapper-row1">
                <el-tooltip :content="item.unitName" placement="top" effect="dark">
                  <div>{{ i + 1 }}.单位:{{ item.unitName }}</div>
                </el-tooltip>
                <el-tooltip :content="item.legalName" placement="top" effect="dark">
                  <div>负责人:{{ item.legalName }}</div>
                </el-tooltip>
              </div>
              <div class="wrapper-row2">
                <el-tooltip :content="item.legalPhone" placement="top" effect="dark">
                  <div>联系电话:{{ item.legalPhone }}</div>
                </el-tooltip>
                <div>
                  <el-button type="text" size="default" @click="open('device',item.unitId)"
                             style="background:transparent;">全部设备<i class="el-icon-arrow-right"></i></el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="camera-monitor">
          <div class="camera-box" ref="cameraBox">
            <!-- <EZUIKit class="camera" :width="1000" :height="600" :videoUrl="streamList" ref="monitor" :splitBasis="9" /> -->
            <HikVision ref="hik" :width="width" :height="height" :pointCode="cameraCode" :layout="'2x2'"
                       v-if="groupVideoShow" :info="cameraInfo" />
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 误报原因 -->
    <el-dialog :visible.sync="mistakeShow" width="30%">
      <el-form :model="error" ref="reportForm" :rules="errorRule" label-width="100px" @close="clear">
        <el-form-item label="误报原因 " prop="alarmReasonType">
          <el-select v-model="error.alarmReasonType" placeholder="请选择误报原因类表" clearable style="float:left">
            <el-option v-for="item in errorReasonList" :key="item.id" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理人" prop="acceptancePerson">
          <el-input autosize placeholder="请输入处理人" v-model="error.acceptancePerson">
          </el-input>
        </el-form-item>
        <el-form-item label="处理过程" prop="acceptanceDesc">
          <el-input type="textarea" autosize placeholder="请输入处理过程" v-model="error.acceptanceDesc" :rows="3">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button size="small" @click="mistakeShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="reportMistake">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 真火警 -->
    <el-dialog title="真火警" append-to-body :visible.sync="fireShow" width="30%" top="2%">
      <el-form :model="fire" ref="fireForm" label-width="120px" @close="clear" :rules="fireRule">
        <el-form-item label="起火时间" prop="reStartTime">
          <el-date-picker v-model="fire.reStartTime" type="datetime" placeholder="选择日期时间" style="float:left">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="起火场所 " prop="firePlaceType">
          <el-select v-model="fire.firePlaceType" placeholder="请选择起火场所" clearable style="float:left">
            <el-option v-for="item in fireSiteList" :key="item.id" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="着火火势" prop="fireIntensity">
          <el-input placeholder="请输入着火火势" v-model="fire.fireIntensity">
          </el-input>
        </el-form-item>
        <el-form-item label="是否有人被困 " prop="isTrapped">
          <el-select v-model="fire.isTrapped" placeholder="请选择是否有人被困" clearable style="float:left">
            <el-option v-for="item in [{id:0,text:'未知'},{id:1,text:'有'},{id:2,text:'无'}]" :key="item.id" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="被困人数" prop="trapped" v-show="fire.isTrapped===1">
          <el-input-number v-model="fire.trapped" :min="1" :controls="true" style="float:left">
          </el-input-number>
        </el-form-item>
        <el-form-item label="起火源" prop="fireSiteType">
          <el-select v-model="fire.fireSiteType" placeholder="请选择起火源" clearable style="float:left">
            <el-option v-for="item in fireSourceList" :key="item.id" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起火原因" prop="fireSiteType">
          <el-select v-model="fire.fireCauseType" placeholder="请选择起火原因" clearable style="float:left">
            <el-option v-for="item in fireReasonList" :key="item.id" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="处理人" prop="acceptancePerson">
          <el-input autosize placeholder="请输入处理人" v-model="fire.acceptancePerson">
          </el-input>
        </el-form-item>
        <el-form-item label="处理过程" prop="acceptanceDesc">
          <el-input type="textarea" autosize placeholder="请输入处理过程" v-model="fire.acceptanceDesc" :rows="3">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button size="small" @click="fireShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="reportFire">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 设备数量列表 -->
    <el-drawer title="设备数量" :visible.sync="deviceShow" direction="rtl" width="40%" class="device-dialog" size="40%">
      <div class="container noangle">
        <div class="header">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="设备特征码">
              <el-input v-model="searchForm.equipmentCode" autocomplete="off" size="small" placeholder="请输入" clearable
                        style="width:100px"></el-input>
            </el-form-item>
            <el-form-item label="设备类型">
              <el-select v-model="searchForm.typeId" placeholder="请选择" size="small" clearable style="width:100px">
                <el-option v-for="(item,i) in typeList" :key="i" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="queryDevice">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content">
          <el-table :data="deviceNumList" stripe style="width: 100%" height="100%">
            <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="address" label="位置" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="lastContactTime" label="状态" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag type="success" size="normal"
                        v-show="(new Date().getTime()-new Date(scope.row.lastContactTime).getTime())<72*60*60*1000">在线
                </el-tag>
                <el-tag type="danger" size="normal"
                        v-show="(new Date().getTime()-new Date(scope.row.lastContactTime).getTime())>72*60*60*1000">离线
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="installTime" label="安装时间" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.installTime|timeFormat }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                         layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="区域选择" :visible.sync="areaShow" width="30%">
      <el-tree ref="tree" :data="areaList" node-key="id" :props="{label:'label'}" empty-text="暂无数据" show-checkbox
               :check-strictly="true" @check-change="checTreekChange"></el-tree>
      <div class="dialog-footer">
        <el-button size="small" @click="areaShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="changeArea">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 告警摄像头 -->
    <el-dialog title="视频"
               v-if="hawkeyeShow"
               :visible.sync="hawkeyeShow"
               class="hawkeyeDialog"
               :close-on-click-modal="false"
               top="3%"
               :modal="false"
               center width="400px">
      <div style="width:100%;height:300px" ref="cameraBox">
        <HikVision :pointCode="hawkeyeCode" :width="width" :height="height" :layout="'1x1'"
                   :info="cameraInfo" @error="hawkeyeShow=false" @plugin-error="hawkeyeShow=false" />
      </div>
    </el-dialog>
    <!-- 电梯告警图片 -->
    <el-dialog class="dialog" :visible.sync="imgShow" width="50%">
      <img :src="elevatorImg" alt="" width="100%">
    </el-dialog>
    <!--离线设备-->
    <device-dialog :visible.sync="deviceDialogVisible" @close="deviceDialogVisible=false" />

    <company-detail-dialog ref="CompanyDetailDialog" />

    <fault-dialog ref="faultDialog"/>
  </div>
</template>

<script>
import _ from 'lodash'
import alarmChart2 from '../../components/chart/alarmChart2.vue'
import pipeChart from '../../components/chart/pipeChart.vue'
import rankingChart from '../../components/chart/rankingChart.vue'
import HikVision from '../../components/HikVision.vue'
import mapList from '../../components/mapList.vue'
import mapList2 from '../../components/mapList2.vue'
import OfflineDeviceTotal from "../device/components/OfflineDeviceTotal"
import DeviceDialog from "./dialogs/DeviceDialog";
import CompanyDetailDialog from "../unit/dialogs/CompanyDetailDialog"
import FaultDialog from './dialogs/FaultDialog'
import store from "./store"

let audioObj = null
export default {
  components: {
    pipeChart,
    rankingChart,
    alarmChart2,
    mapList,
    mapList2,
    HikVision,
    OfflineDeviceTotal,
    DeviceDialog,
    CompanyDetailDialog,
    FaultDialog
  },
  data() {
    return {
      store,
      areaName: '', // 当前
      areaShow: false,
      areaCode: '',
      selectTreeOption: {},
      areaList: [],
      currentPage: 1,
      total: 0,
      styleJson: require('../../assets/mapJson/normal.json'),
      mapStyle: 'normal',
      options: [
        {
          value: 'normal',
          label: '普通主题'
        },
        {
          value: 'tea',
          label: '简约主题'
        },
        {
          value: 'dark',
          label: '暗色主题'
        }
      ],
      // 数据是否完成渲染
      finish: false,
      timer: null,
      showModal: false,
      deviceShow: false,
      drawer: false,
      acitveName: '',
      // 告警窗口
      opts: {
        width: 450,
        height: 280,
        enableMessage: true
      },
      // 单位类型饼图参数
      unitNum: 0,
      unitNumList: [],
      content: {},
      // 地图初始中心点
      center: {
        lng: 115.0196282584,
        lat: 27.1058914875
      },
      zoom: 16,
      map: null,
      addressKeyword: '',
      unitList: [],
      deviceList: [],
      alarmList: [],
      unitMarkers: [],
      deviceMarkers: [],
      deviceTypeList: [],
      errorReasonList: [],
      fireSiteList: [],
      fireSourceList: [],
      fireReasonList: [],
      point: '',
      marker: '',
      label: '',
      setMapStyle: '',
      infoWindow: '',
      markerIcon: '',
      selfMarker: '',
      unitTotal: 0,
      alarmTotal: 0,
      offlineDeviceTotal: 0,
      isBadCount: 0,
      isFireCount: 0,
      // 存放设备类型id的数组
      typeIds: [],
      // 附近消防支队的位置
      fireList: [],
      // 人员列表
      peopleList: [],
      firePeopleFlag: false,
      fireFlag: false,
      alarmId: 0,
      fireStationName: '',
      peopleLocaltion: [],
      deviceType: [],
      deviceNum: [],
      deviceNumList: [],
      // 十户联动单位列表
      cameraList: [],
      // 设备数量图
      warningDate: [],
      // 告警图
      warningNum: [],
      // 左上角告警数据
      alarmData: {},
      alarmData1: [],
      alarmData2: [],
      // 音频文件[]
      audio1: '',
      speechUrl: '',
      // 弹窗
      listAShow: false,
      mistakeShow: false,
      fireShow: false,
      groupVideoShow: false,
      groupShow: false,
      listBShow: false,
      deviceDialogVisible: false,
      // 摄像头
      hawkeyeShow: false,
      imgShow: false,
      flag: false,
      // 摄像头参数配置
      cameraInfo: {},
      cameraCode: '',
      // 摄像头组件宽高
      width: 0,
      height: 0,
      // videoJS参数配置
      playerOptions: {
        height: '360',
        autoplay: true,
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: 'video/mp4',
          // mp4
          src: ''
        }],
        poster: ''
      },
      videoList: [],
      error: {},
      fire: {},
      // 十户联动所需参数
      groupObj: {},
      infoWin: null,
      equipmentId: 0,
      leftFlag: true,
      rightFlag: true,
      middleFlag: true,
      unitTypeData: [],
      rankingData: [],
      allCount: [],
      fireData: [],
      searchForm: {},
      // 设备类型
      typeList: [],
      alarmCount: {},
      unitId: 0,
      alarmCircle: '',
      hawkeyeCode: '',
      // 电梯摄像头抓拍图片
      elevatorImg: '',

      errorRule: {
        acceptancePerson: [
          {required: true, message: '请输入处理人', trigger: 'blur'}
        ],
        alarmReasonType: [
          {required: true, message: '请选择误报原因类表', trigger: 'change'}
        ],
        acceptanceDesc: [
          {required: true, message: '请输入处理过程', trigger: 'blur'}
        ]
      },
      fireRule: {
        acceptancePerson: [
          {required: true, message: '请输入处理人', trigger: 'blur'}
        ],
        acceptanceDesc: [
          {required: true, message: '请输入处理过程', trigger: 'blur'}
        ]
      }
    }
  },
  watch: {
    'store.showAudio': function (v) {
      console.log(v)
      if (v && this.alarmTotal) {
        audioObj.play()
      }
      if (!v) {
        audioObj.pause()
      }
    },
    areaCode(val, oldVal) {
      if (oldVal) {
        this.getUnitPipeData()
        this.getRankingList()
        this.getAllCount()
        this.getFireData()
        this.getAlarmTotal()
        this.getAlarmData()
      }
    },
    alarmList() {
      this.drawAlarm()
      if (this.alarmList.length > 0) {
        // this.changeIcon(3)
        this.showAlarm(this.alarmList[0])
      }
      else {
        this.map.getOverlays().forEach(item => {
          this.map.removeOverlay(item)
        })
      }
    }
  },
  created() {
    if (audioObj == null) {
      audioObj = new Audio()
      audioObj.src = require('../../assets/voices/fire.wav')
      audioObj.loop = 'loop'
    }


    const userCenterLng = sessionStorage.getItem('userLng')
    const userCenterLat = sessionStorage.getItem('userLat')
    this.areaName = sessionStorage.getItem('areaName').replace('江西省', '')
    this.areaCode = sessionStorage.getItem('areaCode')
    if (userCenterLng) {
      this.center.lng = userCenterLng
    }
    if (userCenterLat) {
      this.center.lat = userCenterLat
    }

    // this.getUnitCountChart()// 获取单位数量（图表用）
    // this.getUnitCount()// 只是获取单位数量（轮询）
    this.getUnitPipeData()
    this.getRankingList()
    this.getAllCount()
    this.getFireData()
    // this.getDeviceList()
    this.getTypeList()
    this.getErrorReason()
    this.getFireSite()
    this.getFireSource()
    this.getFireReason()
    this.getAlarmData()

    // 激活视频查看按钮devDisplay
    // window.refuseAlarm = this.refuseAlarm
    window.lookFire = this.lookFire
    window.lookVideo = this.lookVideo
    window.openDialog = this.openDialog
    window.openFireDialog = this.openFireDialog
    window.checkDevice = this.checkDevice
    window.cancelAlarm = this.cancelAlarm
    window.lookHawkeye = this.lookHawkeye
    window.checkElevatorImg = this.checkElevatorImg
  },
  mounted() {
    this.$emit('checkGIS')
    this.getAlarmTypeCount()
    this.timer = setInterval(() => {
      this.getAlarmTotal()
      this.getAllCount()
      this.getAlarmPeople()
    }, 5000)
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
    audioObj.pause()
    audioObj = null
  },
  methods: {
    //点击警情信息统计
    handleAlarmChartClick(position, index) {
      if (position === 'top') {
        if (index === 2) {
          //点击故障信息
          this.$refs['faultDialog'].open()
        }
      }
    },
    showCompanyDialog(id) {
      this.$refs.CompanyDetailDialog.show(id)
      // this.$router.push({path: "/home/company/0", query: {companyId: id}})
    },
    // 点击底部操作面板
    changeIcon(num) {
      if (num === 1) {
        this.acitveName = '单位详情'
        this.listBShow = true
      }
      else if (num === 2) {
        this.acitveName = '设备详情'
        this.listBShow = true
      }
      else if (num === 3) {
        this.acitveName = '设备详情'
        this.deviceDialogVisible = true
      }
      else if (num === 4) {
        this.acitveName = '重点单位'
        this.listAShow = true
      }
      else if (num === 5) {
        this.acitveName = '九小场所'
        this.listBShow = true
      }
      else if (num === 6) {
        this.acitveName = '建筑物'
        this.listBShow = true
      }
      else if (num === 7) {
        this.acitveName = '水源'
        this.listAShow = true
      }
      else if (num === 8) {
        this.acitveName = '摄像头'
        this.listAShow = true
      }
    },
    // 修改地图样式
    changMapType(value) {
      this.mapStyle = value
      switch (value) {
        case 'normal':
          this.styleJson = require('../../assets/mapJson/normal.json')
          break
        case 'tea':
          this.styleJson = require('../../assets/mapJson/tea.json')
          break
        case 'dark':
          this.styleJson = require('../../assets/mapJson/dark.json')
          break
        default:
          break
      }
      this.setMapStyle(this.styleJson)
    },
    open(flag, row) {
      switch (flag) {
        case 'area':
          this.getArea()
          this.areaShow = true
          break
        case 'water':
          this.acitveName = '水源'
          this.listAShow = true
          break
        case 'station':
          this.acitveName = '队站'
          this.listAShow = true
          break
        case 'device':
          this.unitId = row
          this.deviceShow = true
          this.initDevice()
          break
      }
    },
    checTreekChange(data, checked, indeterminate) {
      if (checked) {
        if (data.children) {
          if (data.children.length === 0) {
            this.selectTreeOption = data
          }
        }
        const arr = [data.id]
        this.$refs.tree.setCheckedKeys(arr)
      }
    },
    changeArea() {
      const data = this.$refs.tree.getCheckedNodes()
      if (data.length > 0) {
        this.areaName = data[0].label
        this.areaCode = data[0].areaCode
        this.areaShow = false
      }
    },
    // 地图初始化
    handler({BMap, map}) {
      this.map = map
      this.point = (lng, lat) => {
        return new BMap.Point(lng, lat)
      }
      this.infoWindow = (content, opts) => {
        return new BMap.InfoWindow(content, opts)
      }
      this.marker = (obj) => {
        return new BMap.Marker(obj)
      }
      this.label = (name, num1, num2) => {
        return new BMap.Label(name, {offset: new BMap.Size(num1, num2)})
      }
      this.markerIcon = (imgUrl, num1, num2) => {
        return new BMap.Icon(imgUrl, new BMap.Size(num1, num2))
      }
      this.selfMarker = (pt, myIcon) => {
        return new BMap.Marker(pt, {icon: myIcon})
      }
      this.circle = (pt, radius, setting) => {
        return new BMap.Circle(pt, radius, setting)
      }
      // 地图的初始中心点
      const point = this.point(this.center.lng, this.center.lat)
      this.map.centerAndZoom(point, 16)
      // 默认主题
      this.setMapStyle = (styleJson) => {
        return this.map.setMapStyle({
          styleJson: styleJson
        })
      }
      this.setMapStyle(this.styleJson)
      // 地图初始化后请求告警
      this.getAlarmTotal()
    },
    async getArea() {
      const {data: result} = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        this.areaList = result.data
      }
    },
    // 只是获取单位数量
    async getUnitCount() {
      const data = {
        pageNo: 1,
        pageSize: 10
      }
      if (this.areaCode) {
        data.areaCode = this.areaCode
      }
      const {data: result} = await this.$axios.get('/unit/listWeb', {params: data})
      if (result.code === 200) {
        this.unitTotal = result.data.total
      }
      else {
        this.$message.error(result.msg)
      }
      // console.log('轮询单位数量:' + this.unitTotal)
    },
    // 轮询获取告警信息
    async getAlarmTotal() {
      const {data: result} = await this.$axios.get('/alarm/pollCurrentAlarm', {
        params: {
          pageNo: 1,
          pageSize: 100,
          areaCode: this.areaCode
        }
      })
      this.alarmTotal = result.data.total
      if (this.alarmTotal > 0 && store.showAudio) {
        audioObj.play()
      }
      else {
        audioObj.pause()
      }
      if (!_.isEqual(this.alarmList, result.data.result)) {
        this.alarmList = result.data.result
      }
      if (result.data.total === 0) {
        this.drawer = false
      }
    },
    // 轮询画告警点
    drawAlarm() {
      if (this.alarmList.length > 0) {
        this.alarmList.forEach((item, index) => {
          const point = this.point(item.lng, item.lat)
          const myIcon = this.markerIcon(require('../../assets/img/' + 'alarm' + '.gif'), 50, 50)
          const marker = this.selfMarker(point, myIcon)
          marker.type = 'alarm'
          marker.addEventListener('click', (e) => {
            this.showAlarm(item)
          })
          this.map.addOverlay(marker)
        })
      }
      else {
        //  遍历所有的告警信息
        if (this.map.getOverlays()) {
          this.map.getOverlays().forEach(item => {
            if (item.type === 'alarm') {
              this.map.removeOverlay(item)
              return false
            }
          })
        }
      }
    },
    // 告警的消息框
    async showAlarm(item) {
      const point = this.point(item.lng, item.lat)
      const {data: resp} = await this.$axios.get('/alarm/detail/' + item.id)
      let result = resp.data

      if (this.alarmCircle) {
        this.map.removeOverlay(this.alarmCircle)
      }
      this.alarmCircle = this.circle(point, 1000, {
        fillColor: 'blue',
        strokeColor: 'blue',
        strokeWeight: 1,
        strokeOpacity: 0.3,
        fillOpacity: 0.3
      })
      this.map.addOverlay(this.alarmCircle)
      this.map.centerAndZoom(point, 16)
      let content = ''
      // const timeFormat = Vue.filter('timeFormat')
      // const date = timeFormat(result.alarmDate)
      let img = ''
      const imgUrl = require('../../assets/img/failed.png')
      const fireIcon = require('../../assets/img/fire-alarm.png')
      if (result.unitImage) {
        img = `<img src="${result.unitImage}" width="100%" height="100%" alt="单位图片">`
      }
      else {
        img = `<img src="${imgUrl}" width="100%" height="100%" alt="暂无图片">`
      }
      switch (result.equipmentTypeId) {
        case 14: {
          content = `
         <div class="alarmInfoBox" style="font-size:14px;display: flex;flex-direction: column;justify-content: space-between;width:100%;height:100%">
            <div style="font-size:16px;margin-bottom: 5px;padding-bottom: 5px;border-bottom: 1px solid #ccc;">
              <div style="width:70%;float:left">
                <h1>${result.unitName}</h1>
                <p style="color:#333;font-size:12px;">${result.fullAreaName}</p>
              </div>
               <div style="width:30%;color:red;float:left">
                <h1 style="font-size:24px;"><img src="${fireIcon}" width="18px" height="18px" style="vertical-align:middle"/>真火警</h1>
              </div>
            </div>
            <div style="width:100%;display: flex;">
              <div style="width:140px;height:160px;margin-right:6px">` + img + `</div>
              <div>
                <p>设备类型:${result.equipmentTypeName}</p>
                <p>告警类型:${result.alarmTypeName}</p>
                <p>内容:${result.content}</p>
                  <p>单位地址:${result.unitAddress || '暂无地址'}</p>
                <p>安装地址:${result.installAddress || '暂无地址'}</p>
                <p>联系人:${result.legalName}</p>
                <p>联系电话:${result.legalPhone || '暂无电话'}</p>
                <p style="color:#F56C6C">告警时间:${result.alarmDate}</p>
              </div>
            </div>
            <div style="display: flex;justify-content:space-between; aligin-items: center;margin-top: 10px;padding-top: 10px;border-top: 1px solid #ccc;">
              <input type="button" value="附近资源" onclick="lookFire(${result.id})" style="box-sizing:border-box;background:#409eff;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="误报" onclick="openDialog(${result.id})" style="box-sizing:border-box;background:#E6A23C;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="排查" style="box-sizing:border-box;background:#67C23A;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="视频" onclick="lookHawkeye('${result.equipmentId}',${result.areaCode},${result.unitId})" style="box-sizing:border-box;background:#163D64;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="设备" onclick="checkDevice(${result.unitId})" style="box-sizing:border-box;background:#fbbd08;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="十户联动" onclick="lookVideo(${result.areaCode}, '${result.equipmentTypeId}', ${result.groupId}, '${result.speechUrl}', ${result.equipmentId})" style="box-sizing:border-box;background:#409EFF;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
             </div>
          </div>`
          break
        }
        default: {
          // 根据告警类型渲染
          if (result.alarmFlag === 1) {
            content = `
          <div class="alarmInfoBox" style="font-size:14px;display: flex;flex-direction: column;justify-content: space-between;width:100%;height:100%">
            <div style="font-size:16px;margin-bottom: 5px;padding-bottom: 5px;border-bottom: 1px solid #ccc">
              <div style="width:70%;float:left">
                <h1>${result.unitName}</h1>
                <p style="color:#333;font-size:12px;">${result.fullAreaName}</p>
              </div>
               <div style="width:30%;color:red;font-size:18px;float:left">
                <h1><img src="${fireIcon}" width="18px" height="18px" style="vertical-align:middle"/>真火警</h1>
              </div>
            </div>
            <div style="width:100%;display: flex;">
              <div style="width:140px;height:160px;margin-right:6px">` + img + `</div>
              <div>
                <p>设备类型:${result.equipmentTypeName}</p>
                <p>告警类型:${result.alarmTypeName}</p>
                <p>内容:${result.content}</p>
                <p>单位地址:${result.unitAddress || '暂无地址'}</p>
                <p>安装地址:${result.installAddress || '暂无地址'}</p>
                <p>联系人:${result.legalName}</p>
                <p>联系电话:${result.legalPhone || '暂无电话'}</p>
                <p style="color:#F56C6C">告警时间:${result.alarmDate}</p>
              </div>
            </div>
            <div style="display: flex;justify-content:space-between; aligin-items: center;margin-top: 10px;padding-top: 10px;border-top: 1px solid #ccc;">
              <input type="button" value="附近资源" onclick="lookFire(${result.id})" style="box-sizing:border-box;background:#409eff;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="解除告警" onclick="cancelAlarm(${result.id})" style="box-sizing:border-box;background:#E6A23C;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="排查" style="box-sizing:border-box;background:#67C23A;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="视频" onclick="lookHawkeye('${result.equipmentId}',${result.areaCode},${result.unitId})" style="box-sizing:border-box;background:#163D64;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="设备" onclick="checkDevice(${result.unitId})" style="box-sizing:border-box;background:#fbbd08;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="十户联动" onclick="lookVideo(${result.areaCode}, '${result.equipmentTypeId}', ${result.groupId}, '${result.speechUrl}', ${result.equipmentId})" style="box-sizing:border-box;background:#409EFF;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
            </div>
          </div>`
          }
          else if (result.alarmFlag === 0) {
            content = `
          <div class="alarmInfoBox" style="font-size:14px;display: flex;flex-direction: column;width:100%;height:100%">
            <div style="font-size:16px;margin-bottom: 5px;padding-bottom: 5px;border-bottom: 1px solid #ccc;">
               <h1>${result.unitName}</h1>
                <p style="color:#333;font-size:12px;">${result.fullAreaName}</p>
            </div>
            <div style="width:100%;display: flex;">
              <div style="width:140px;height:160px;margin-right:6px">
              <div style="width:140px;height:160px;margin-right:6px">` + img + `</div>
              </div>
              <div>
                 <p>设备类型:${result.equipmentTypeName}</p>
                <p>告警类型:${result.alarmTypeName}</p>
                <p>内容:${result.content}</p>
                <p>单位地址:${result.unitAddress || '暂无地址'}</p>
                <p>安装地址:${result.installAddress || '暂无地址'}</p>
                <p>联系人:${result.legalName}</p>
                <p>联系电话:${result.legalPhone || '暂无电话'}</p>
                <p style="color:#F56C6C">告警时间:${result.alarmDate}</p>
              </div>
            </div>
            <div style="display: flex;justify-content:space-between; aligin-items: center;margin-top: 10px;padding-top: 10px;border-top: 1px solid #ccc;">
              <input type="button" value="附近资源" onclick="lookFire(${result.id})" style="box-sizing:border-box;background:#409eff;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="误报" onclick="openDialog(${result.id})" style="box-sizing:border-box;background:#E6A23C;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="排查" style="box-sizing:border-box;background:#67C23A;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="视频" onclick="lookHawkeye('${result.equipmentId}',${result.areaCode},${result.unitId})" style="box-sizing:border-box;background:#163D64;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="设备" onclick="checkDevice(${result.unitId})" style="box-sizing:border-box;background:#fbbd08;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="确认火警" onclick="openFireDialog(${result.id},${result.areaCode}, '${result.equipmentTypeId}', ${result.groupId})" style="box-sizing:border-box;background:#F56C6C;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
            </div>
          </div>`
            this.lookHawkeye(result.equipmentId, result.areaCode, result.unitId)
          }
          else if (result.alarmFlag === 4) {
            this.opts = {
              width: 500,
              height: 260,
              enableMessage: true
            }
            content = `
          <div class="alarmInfoBox" style="font-size:14px;display: flex;flex-direction: column;width:100%;height:100%">
            <div style="font-size:16px;margin-bottom: 5px;padding-bottom: 5px;border-bottom: 1px solid #ccc;">
              <h1>${result.unitName}</h1>
                <p style="color:#333;font-size:12px;">${result.fullAreaName}</p>
            </div>
            <div style="width:100%;display: flex;">
              <div style="width:140px;height:160px;margin-right:6px">
              <div style="width:140px;height:160px;margin-right:6px">` + img + `</div>
              </div>
              <div>
                 <p>设备类型:${result.equipmentTypeName}</p>
                <p>告警类型:${result.alarmTypeName}</p>
                <p>内容:${result.content}</p>
                <p>单位地址:${result.unitAddress || '暂无地址'}</p>
                <p>安装地址:${result.installAddress || '暂无地址'}</p>
                <p>联系人:${result.legalName}</p>
                <p>联系电话:${result.legalPhone || '暂无电话'}</p>
                <p style="color:#F56C6C">告警时间:${result.alarmDate}</p>
              </div>
              <div style="width:140px;height:160px;margin-left:6px;cursor:pointer" onclick="checkElevatorImg('${result.imgUrl}')">
                <img src="${item.imgUrl}" width="100%" height="100%" alt="暂无图片">
              </div>
            </div>
            <div style="display: flex;justify-content:space-between; aligin-items: center;margin-top: 10px;padding-top: 10px;border-top: 1px solid #ccc;">
              <input type="button" value="解除告警" onclick="cancelAlarm(${result.id})" style="box-sizing:border-box;background:#E6A23C;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="视频" onclick="lookHawkeye('${result.equipmentId}',${result.areaCode},${result.unitId})" style="box-sizing:border-box;background:#163D64;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="设备" onclick="checkDevice(${result.unitId})" style="box-sizing:border-box;background:#fbbd08;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
             </div>
          </div>`
            this.lookHawkeye(result.equipmentId, result.areaCode, result.unitId)
          }
          else {
            content = `
          <div class="alarmInfoBox" style="font-size:14px;display: flex;flex-direction: column;width:100%;height:100%">
            <div style="font-size:16px;margin-bottom: 5px;padding-bottom: 5px;border-bottom: 1px solid #ccc;">
               <h1>${result.unitName}</h1>
                <p style="color:#333;font-size:12px;">${result.fullAreaName}</p>
            </div>
            <div style="width:100%;display: flex;">
              <div style="width:140px;height:160px;margin-right:6px">
              <div style="width:140px;height:160px;margin-right:6px">` + img + `</div>
              </div>
              <div>
               <p>设备类型:${result.equipmentTypeName}</p>
                <p>告警类型:${result.alarmTypeName}</p>
                <p>内容:${result.content}</p>
                <p>单位地址:${result.unitAddress || '暂无地址'}</p>
                <p>安装地址:${result.installAddress || '暂无地址'}</p>
                <p>联系人:${result.legalName}</p>
                <p>联系电话:${result.legalPhone || '暂无电话'}</p>
                <p style="color:#F56C6C">告警时间:${result.alarmDate}</p>
              </div>
            </div>
            <div style="display: flex;justify-content:space-between; aligin-items: center;margin-top: 10px;padding-top: 10px;border-top: 1px solid #ccc;">
              <input type="button" value="附近资源" onclick="lookFire(${result.id})" style="box-sizing:border-box;background:#409eff;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="误报" onclick="openDialog(${result.id})" style="box-sizing:border-box;background:#E6A23C;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="排查" style="box-sizing:border-box;background:#67C23A;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="视频" onclick="lookHawkeye('${result.equipmentId}',${result.areaCode},${result.unitId})" style="box-sizing:border-box;background:#163D64;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
              <input type="button" value="设备" onclick="checkDevice(${result.unitId})" style="box-sizing:border-box;background:#fbbd08;color:#fff;border-radius: 20px;padding: 5px 12px;border: none;outline: none;box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);CURSOR: POINTER;"/>
            </div>
          </div>`
            this.lookHawkeye(result.equipmentId, result.areaCode, result.unitId)
          }
          break
        }
      }

      this.infoWin = this.infoWindow(content, this.opts)
      this.map.openInfoWindow(this.infoWin, point)
    },
    // 发生告警时播放声音
    async alarmVideo() {
      this.audio1 = new Audio()
      this.audio1.src = this.speechUrl
      this.audio1.play()

      this.$confirm('是否发送紧急救援通知？')
          .then(async _ => {
            const {data: result} = await this.$axios.post('/alarm/sendInfoAndForm', {equipmentId: this.equipmentId})
            if (result.code === 200) {
              this.$message.success('发送成功')
            }
            else {
              this.$message.error(result.msg)
            }
          })
          .catch(_ => {
          })
    },
    // 画出就近消防站
    drawFire() {
      const allOverlay = this.map.getOverlays()
      allOverlay.forEach(item => {
        if (item.type === 'fire') {
          this.map.removeOverlay(item)
          return false
        }
      })
      if (this.fireList.length > 0) {
        //  遍历所有的告警信息
        this.fireList.forEach(item => {
          const point = this.point(item.lng, item.lat)
          const myIcon = this.markerIcon(require('../../assets/img/fire.png'), 50, 50)
          const marker = this.selfMarker(point, myIcon)
          const label = this.label(item.name, 30, -20)
          marker.addEventListener('click', (e) => {
            this.firePeopleFlag = true
            this.peopleList = item.persons
            this.fireStationName = item.name
          })
          marker.type = 'fire'
          marker.setLabel(label)
          this.map.addOverlay(marker)
        })
      }
    },
    // 轮询当前接警人员的位置
    async getAlarmPeople() {
      const {data: result} = await this.$axios.get('/alarm/getCurrentFireBrigadeForWeb')
      if (result.code !== 200) return this.$message.error(result.msg)
      this.peopleLocaltion = result.data
      this.drawfirePeople()
    },
    // 画出当前已接警人员的位置
    drawfirePeople() {
      // 清除之前人员的位置
      if (this.map.getOverlays() !== null) {
        this.map.getOverlays().forEach(item => {
          if (item.type === 'people') {
            this.map.removeOverlay(item)
            return false
          }
        })
      }
      if (this.peopleLocaltion.length > 0) {
        this.peopleLocaltion.forEach(item => {
          const point = this.point(item.lng, item.lat)
          const myIcon = this.markerIcon(require('../../assets/img/firePeople.png'), 50, 50)
          const marker = this.selfMarker(point, myIcon)
          marker.type = 'people'
          const label = this.label(item.name, 30, -20)
          marker.setLabel(label)
          this.map.addOverlay(marker)
        })
      }
    },
    // 查看就近消防支队
    lookFire(id) {
      this.alarmId = id
      this.firePeopleFlag = false
      this.fireFlag = true
      this.map.setZoom(15)
      this.alarmList.forEach(async (item, index) => {
        if (item.id === id) {
          console.log(item)
          const {data: result} = await this.$axios.get('/alarm/getNearestFireStation', {
            params: {
              areaCode: item.areaCode,
              lng: item.equipmentObject.lng,
              lat: item.equipmentObject.lat
            }
          })
          if (result.code === 200) {
            this.fireList = result.data
          }
        }
      })
      this.drawFire()
    },
    // infowindow 视频
    async lookHawkeye(id, areaCode, unitId) {
      if (parseInt(id) === 201004 || parseInt(id) === 201092) {
        this.hawkeyeCode = 'cf34fb128cec480a88e27735f785ea9b,dbda2d0385334c569840821d440269aa'
        this.cameraInfo = {
          appKey: '28022728',
          appSecret: 'wACQUoEN8q08bu1DzHL1',
          host: '61.180.39.9:1443'
        }
        this.hawkeyeShow = true
        this.$nextTick(() => {
          this.width = this.$refs.cameraBox.offsetWidth
          this.height = this.$refs.cameraBox.offsetHeight
        })
      }
      else {
        this.initCameraSource(areaCode)
        try {
          const {data: result} = await this.$axios.get('/alarm/getCameraCodeList', {params: {unitId: +unitId}})
          this.hawkeyeCode = result.data.filter(item => item).join(',')
          if (this.hawkeyeCode !== '') {
            this.hawkeyeShow = true
            this.$nextTick(() => {
              this.width = this.$refs.cameraBox.offsetWidth
              this.height = this.$refs.cameraBox.offsetHeight
            })
          }
          else throw({msg: "该单位暂未录入监控点"})
        } catch (e) {
          this.$message.warning(e.msg)
          this.hawkeyeShow = false
        }
      }
    },
    // 派警
    async sendAlarm(id) {
      console.log(id)
      const {data: result} = await this.$axios.get('/alarm/setFireBrigadeForWeb', {
        params: {
          alarmId: this.alarmId,
          userId: id
        }
      })
      if (result.code === 200) {
        this.fireFlag = false
        this.$message.success('派警成功！')
      }
      else {
        this.$message.error(result.msg)
      }
    },
    // 真火警
    reportFire() {
      this.hawkeyeShow = true
      this.$refs.fireForm.validate(async valid => {
        if (valid) {
          this.fire.alarmId = this.alarmId
          const {data: result} = await this.$axios.post('/alarm/reallyAlarm', this.fire)
          if (result.code === 200) {
            this.infoWin.close()
            setTimeout(() => {
              this.infoWin.open()
            }, 300)
            this.fireShow = false
            this.getAlarmTotal()
            this.hawkeyeShow = false
            // this.lookVideo(this.groupObj.areaCode, this.groupObj.devType, this.groupObj.groupId)
            this.$message.success('提交成功')
          }
          else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 误报
    reportMistake() {
      this.$refs.reportForm.validate(valid => {
        if (valid) {
          this.$confirm('确认该告警为误报？')
              .then(async _ => {
                this.error.alarmId = this.alarmId
                await this.$axios.post('/alarm/errorReport', this.error)
                this.mistakeShow = false
                this.getAlarmTotal()
                this.$message.success('提交成功')
              })
              .catch(_ => {
              })
        }
      })
    },
    // 取消告警
    async cancelAlarm(id) {
      this.$confirm('是否解除该火警？')
          .then(async _ => {
            await this.$axios.put('/alarm/cancelAlarm', {alarmId: id, status: -1})
            this.hawkeyeShow = false
            this.getAlarmTotal()
            this.$message.success('取消成功')
          })
          .catch(_ => {
          })
    },
    // 同步告警至省平台
    async setAlarmSync(id) {
      const confirm = await this.$confirm('是否同步该告警至省平台?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.post(`/alarm/alarmSyncProvince/${id}`)
        if (result.code === 200) {
          this.initTable()
          this.$message.success('同步成功')
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 查看视频
    async lookVideo(areaCode, devType, groupId, equipmentId) {
      this.initCameraSource(areaCode)

      if (groupId === 0) return this.$message.error('该单位暂未绑定分组')
      const obj = {
        groupId: groupId
      }
      if (devType === 14) {
        obj.typeListIds = devType
      }
      const {data: result} = await this.$axios.get('/equipment/findEquipmentByUnitIdWithTypeId', {
        params: obj
      })
      if (result.code !== 200) return this.$message.error(result.msg)

      this.cameraList = result.data
      let array = []
      for (let i = 0; i < result.data.length; i++) {
        if (result.data[i].playUrl.length > 0) {
          array = array.concat(result.data[i].playUrl)
          array = array.filter(item => item)
        }
      }

      this.cameraCode = array.join(',')
      this.groupShow = true
      this.$nextTick(() => {
        this.width = this.$refs.cameraBox.offsetWidth
        this.height = this.$refs.cameraBox.offsetHeight
        this.groupVideoShow = true
      })
    },
    // 获取海康威视服务器配置
    async initCameraSource(areaCode) {
      console.log(areaCode)
      const {data: result} = await this.$axios.get('/cameraParams/list', {params: {areaCode: areaCode}})
      if (result.code === 200) {
        this.cameraInfo = result.data[0]
      }
    },
    // 查看设备
    checkDevice(id) {
      this.unitId = id
      this.deviceShow = true
      this.initDevice()
    },
    // 查看电梯图片
    checkElevatorImg(img) {
      this.elevatorImg = img
      this.imgShow = true
    },
    async handleCurrentChange(val) {
      this.currentPage = val
      this.initDevice()
    },
    // 查看告警单位设备
    async initDevice() {
      const data = {
        ...this.searchForm,
        unitId: this.unitId,
        pageNo: this.currentPage,
        pageSize: 10
      }
      const {data: result} = await this.$axios.get('/equipment/list', {params: data})
      if (result.code === 200) {
        this.deviceNumList = result.data.result
        this.total = result.data.total
      }
    },
    // 条件查询告警单位设备
    async queryDevice() {
      const data = {}
      if (this.searchForm.typeId) {
        data.typeId = this.searchForm.typeId
      }
      if (this.searchForm.equipmentCode) {
        data.equipmentCode = this.searchForm.equipmentCode
      }
      data.unitId = this.unitId
      const {data: result} = await this.$axios.get('/equipment/list', {params: data})
      if (result.code === 200) {
        this.deviceNumList = result.data.result
      }
    },
    // 获取设备数量（图表）
    async getDeviceList() {
      const data = {}
      if (this.areaCode) {
        data.areaCode = this.areaCode
      }
      const {data: result} = await this.$axios.get('/equipment/countByType', {params: data})
      if (result.code === 200) {
        for (let i = 0; i < result.data; i++) {
          if (result.data.length !== i + 1) {
            this.deviceType.push(result.data[i].typeName)
            this.deviceNum.push(result.data[i].count)
          }
        }
      }
      this.finish = true
    },
    // 获取告警数量（图表）
    async getAlarmList() {
      const data = {}
      if (this.areaCode) {
        data.areaCode = this.areaCode
      }
      const {data: result} = await this.$axios.get('/alarm/getMonthSortAlarm', {params: data})
      if (result.code === 200) {
        result.data.forEach((item, i) => {
          this.warningDate.push(`${item.mont}月`)
          this.warningNum.push(item.count)
        })
      }
    },
    openDialog(id) {
      this.alarmId = id
      this.mistakeShow = true
      this.$nextTick(() => {
        this.$refs.reportForm.clearValidate()
      })
    },
    // 真火警dialog
    openFireDialog(id, areaCode, devType, groupId) {
      this.groupObj = {
        areaCode: areaCode,
        devType: devType,
        groupId: groupId
      }
      const date = new Date()
      this.$set(this.fire, 'reStartTime', date)
      this.alarmId = id
      this.fireShow = true
    },
    // 查询单位分类的饼图
    async getUnitPipeData() {
      const data = {}
      if (this.areaCode) {
        data.areaCode = this.areaCode
      }
      const {data: res} = await this.$axios.get('/unit-type/listTotal', {params: data})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.unitTypeData = res.data
    },
    // 查询当前所在区域的排行
    async getRankingList() {
      const data = {}
      if (this.areaCode) {
        data.areaCode = this.areaCode
      }
      const {data: res} = await this.$axios.get('/equipment/countByArea', {params: data})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.rankingData = res.data
    },
    // 获得告警数据
    async getAlarmData() {
      const data = {}
      if (this.areaCode) {
        data.areaCode = this.areaCode
      }
      const {data: res} = await this.$axios.get('/alarm/alarmInfoCount', {params: data})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.alarmData = res.data[0]
      this.alarmData1 = [res.data[0].alarmInfo, res.data[0].realAlarm, res.data[0].troubleInfo]
      this.alarmData2 = [res.data[0].errorInfo, res.data[0].maintenance, res.data[0].patrolTrouble]
    },
    // 获取所有数据的统计
    async getAllCount() {
      const data = {}
      if (this.areaCode) {
        data.areaCode = this.areaCode
      }
      const {data: res} = await this.$axios.get('/equipment/count', {params: data})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.allCount = res.data
    },
    // 获取消防力量统计
    async getFireData() {
      const data = {}
      if (this.areaCode) {
        data.areaCode = this.areaCode
      }
      const {data: res} = await this.$axios.get('/equipment/countWaterAndFireBrigade', {params: data})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.fireData = res.data
    },
    // 查找设备类型
    async getTypeList() {
      const {data: result} = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      }
    },
    // 告警类型数量
    async getAlarmTypeCount() {
      const {data: result} = await this.$axios.get('/alarm/recordByDay')
      if (result.code === 200) {
        this.alarmCount = result.data[0]
      }
    },
    // 误报原因
    async getErrorReason() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'alarmReasonType'}})
      if (result.code === 200) {
        this.errorReasonList = result.data
      }
    },
    // 起火场所
    async getFireSite() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'fireSite'}})
      if (result.code === 200) {
        this.fireSiteList = result.data
      }
    },
    // 起火源
    async getFireSource() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'fireSource'}})
      if (result.code === 200) {
        this.fireSourceList = result.data
      }
    },
    // 起火原因
    async getFireReason() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'fireReason'}})
      if (result.code === 200) {
        this.fireReasonList = result.data
      }
    },
    skipPage(index) {
      switch (index) {
        case 1:
          window.open('http://jaxf.jjxsw.vip/#/zg119/provinceVideo?areaCode=36080390&unitId=13077')
          break
        case 2:
          window.open('http://jaxf.jjxsw.vip/#/zg119/provinceVideo?areaCode=36080390&unitId=13078')
          break
      }
    },
    clear() {
      this.error = {}
      this.fire = {}
    }
  }
}
</script>

<style lang="scss" scoped>
//@import url("//unpkg.com/element-ui@2.14.1/lib/theme-chalk/index.css");

.container_body {
  width: 100%;
  height: 100vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 10px;
  box-sizing: border-box;

  .left-col {
    flex: 1;
    margin-right: 10px;
    overflow: hidden;
  }

  .middle-col {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .map-row {
      flex: 5;
      background: url("../../assets/img/main-box3.png") no-repeat center center;
      background-size: 100% 100%;
      padding: 2%;
      box-sizing: border-box;

      .bmView {
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    .large-row {
      flex: 1;
      padding: 10px;
      box-sizing: border-box;

      .content {
        background: url("../../assets/img/main-box4.png") no-repeat center center;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        color: #fff;
        justify-content: space-between;

        .btn1 {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-sizing: border-box;
          white-space: nowrap;

          .num {
            font-size: 24px;
            font-weight: bold;
          }

          .name {
            padding-top: 5px;
            font-size: 14px;
          }
        }

        .btn_group {
          flex: 7;
          overflow: hidden;
          transition: all 0.5s;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .btn {
            cursor: pointer;
            flex: 1;
            display: flex;
            align-items: center;
            flex-direction: column;
            border-right: 1px solid #999;
            justify-content: space-between;

            &:last-child {
              border-right: none;
            }

            //&:first-child {
            //  border-left: 1px solid #999;
            //}

            .num {
              font-size: 24px;
              font-weight: bold;
            }

            .name {
              padding-top: 5px;
              font-size: 14px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .right-col {
    flex: 1;
    margin-left: 20px;
  }

  .left-col,
  .right-col {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .chart-row {
      flex: 1;
      padding: 10px;
      box-sizing: border-box;

      .content {
        width: 100%;
        height: 100%;
        background: url("../../assets/img/main-box1.png") no-repeat center center;
        background-size: 100% 100%;
        padding: 10px;
        box-sizing: border-box;
        position: relative;

        .title {
          color: #3debff;
          height: 20px;
          font-size: 14px;
          position: absolute;
          left: 6%;
          top: 2%;
          text-align: left;
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
          }

          .alarm-count {
            font-size: 8px;
            margin-left: 5px;
          }

          .alarm-count:nth-child(2) {
            color: #bfa527;
          }

          .alarm-count:nth-child(3) {
            color: #0dc8d2;
          }

          .alarm-count:nth-child(4) {
            color: #af5a32;
          }

          .alarm-count:nth-child(5) {
            color: #3478d9;
          }
        }

        .alarm {
          width: 100%;
          height: 100%;

          .alarm-content {
            height: 40%;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 20px 15px 0px 15px;
            box-sizing: border-box;

            .content-box:first-child {
              box-sizing: border-box;
              border-bottom: 1px dashed rgba(0, 117, 255, 0.3);
              padding: 5px 0;

              .alarm-data:first-child {
                float: left;

                span {
                  color: #3debff;
                }
              }

              .alarm-data:last-child {
                float: right;

                p:first-child {
                  > span {
                    color: #e39bef;
                  }
                }

                p:last-child {
                  > span {
                    color: #008aff;
                  }
                }
              }
            }

            .content-box:last-child {
              box-sizing: border-box;
              padding-top: 5px;

              .alarm-data:first-child {
                float: left;

                span {
                  color: #e07f38;
                }
              }

              .alarm-data:last-child {
                float: right;

                p {
                  > span {
                    color: #fb1800;
                  }
                }
              }
            }

            .content-box {
              .alarm-data {
                display: flex;
                align-items: center;
                width: 50%;

                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                }

                p {
                  font-size: 12px;
                  text-align: left;

                  span {
                    font-size: 14px !important;
                  }
                }
              }
            }
          }

          .alarm-chart {
            height: 30%;
            width: 100%;
            position: relative;
          }
        }

        .fire-data {
          width: 100%;
          height: 100%;
          padding-top: 15px;
          box-sizing: border-box;

          .fire {
            width: 50%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            float: left;
            position: relative;
            font-size: 14px;
            cursor: pointer;

            img {
              width: 90px;
              height: 80px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            span:nth-child(2) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            span:nth-child(3) {
              position: absolute;
              top: 80%;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        #pipeChart1 {
          position: absolute;
          top: 0;
          bottom: 20px;
          right: 0;
          left: 0;
          margin-top: 30px;
        }

        > .item_box:first-child {
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          right: 50%;
          bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #f2f2f2;

          .item_num {
            color: #fe2c8a;
            font-size: 24px;
            font-weight: bold;
          }

          .item_title {
            font-size: 14px;
          }
        }

        > .item_box:last-child {
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 50%;
          right: 0;
          bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #f2f2f2;

          .item_num {
            color: #01a4f7;
            font-size: 24px;
            font-weight: bold;
          }

          .item_title {
            font-size: 14px;
          }
        }

        ::v-deep .el-table {
          border: none;
          background-color: transparent;
        }

        ::v-deep .el-table th {
          color: #f2f2f2;
          background-color: transparent;
        }

        ::v-deep .el-table tr {
          color: #f2f2f2;
          border: none;
          background-color: transparent;
        }

        ::v-deep .el-table td,
        .el-table th.is-leaf {
          border: none;
        }

        ::v-deep .el-table tr:hover > td {
          cursor: pointer;
          color: #fff;
          background-color: rgba(28, 97, 160, 0.4);
        }

        .el-table--border::after,
        .el-table--group::after,
        .el-table::before {
          content: "";
          background-color: transparent;
        }
      }
    }

    .mini {
      flex: 0.5;
    }

    .small {
      flex: 0.65;
    }
  }

  .left-col {
    height: 100%;
    //flex: none;
    .chart-row {
      &:first-child {
        height: 30vmin;
        flex: none;
      }

      &:nth-child(2) {
        height: 20vmin;
        flex: none;
      }

      &:last-child {
        height: calc(100% - 50vmin);
        flex: none;
      }
    }
  }

  //.left-col{
  //  display: grid;
  //  grid-template-rows: 1fr 0.5fr 1fr;
  //  justify-content: normal;
  //  .chart-row{
  //    flex: none;
  //  }
  //}
  .area {
    position: absolute;
    right: 2%;
    top: -8px;

    .el-button {
      color: #fff;
    }
  }

  .people_box {
    width: 20%;
    height: auto;
    z-index: 10;
    position: absolute;
    top: 10px;
    padding: 10px;
    display: flex;
    font-size: 14px;
    transition: all 0.5s;
    color: #f2f2f2;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    background-color: rgba(15, 46, 75, 0.8);
    flex-direction: column;
    justify-content: flex-start;

    .item_list {
      .list-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .el-button {
      margin-top: 5px;
    }
  }
}

.dialog1 {
  height: 100%;
  // ::v-deep  .el-dialog__header {
  //   display: none !important;
  // }
  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }
}

.dialog {
  margin: 0;
  padding: 10px;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    .tip {
      text-align: center;
      color: #303133;
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.video_box {
  width: 100%;

  .video_title {
    height: 30px;
    display: flex;
    align-items: center;
    background-color: rgba(4, 3, 53, 0.4);
    border-top: 1px solid rgba(4, 3, 53, 0.6);
    border-bottom: 1px solid rgba(4, 3, 53, 0.6);
    padding: 0 10px;
    box-sizing: border-box;

    > div {
      flex: 1;
      text-align: left;
      color: #ffff;
    }

    > div:last-child {
      flex: 0.5;
    }
  }

  .video_content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .video_player {
      width: 32%;
      margin: 5px;
      display: flex;
      position: relative;
      overflow: hidden;
      flex-direction: column;
      justify-content: space-between;

      > div:first-child {
        height: 100%;
        width: 100%;
      }
    }
  }

  // .video_player {
  //   width: 100%;
  //   display: flex;
  //   justify-content: space-between;
  // }
}

::v-deep :focus {
  outline: 0;
}

::v-deep .el-dropdown-menu {
  color: #f2f2f2;
  border: 1px solid rgba(61, 194, 241, 0.285) !important;
  background-color: rgba(15, 46, 75, 0.8);
}

.el-tree {
  margin-top: 10px;
  background-color: transparent;
  color: #fff;

  ::v-deep .el-tree-node__content:hover {
    background-color: rgba(30, 74, 115, 0.5);
  }

  ::v-deep .el-tree-node:focus > .el-tree-node__content {
    background-color: rgba(30, 74, 115, 0.5);
  }
}

.monitor-container {
  height: 600px;
  width: 100%;
  display: flex;

  .camera-list {
    flex: 3;
    background-color: transparent;
    overflow: hidden;

    .list-button {
      display: flex;
      justify-content: space-around;
    }

    .list-box {
      height: calc(100% - 30px);
      overflow-y: scroll;

      .list-wrapper {
        padding: 5px;
        text-align: left;
        border-bottom: 1px solid #eee;
        transition: all linear 0.3s;

        .wrapper-row1 {
          width: 100%;
          color: #fff;
          font-size: 13px;
          display: flex;

          div {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .wrapper-row2 {
          width: 100%;
          margin-top: 10px;
          color: #409eff;
          font-size: 13px;
          display: flex;
          align-items: center;

          div {
            flex: 1;
            width: 50%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      .list-wrapper:hover {
        background-color: rgba($color: #409eff, $alpha: 0.5);

        div:first-child {
          color: #fff;
        }

        div:last-child {
          color: #f5f5f5;
        }
      }
    }
  }

  .camera-monitor {
    flex: 8;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    .camera-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .danger {
    color: #f56c6c;
  }

  .success {
    color: #67c23a;
  }
}

.group-dialog {
  ::v-deep .el-dialog {
    margin-left: 1%;
    position: relative;

    .list-button {
      width: 200px;
      position: absolute;
      top: 2%;
      left: 150px;
    }
  }
}

.zg119-dialog {
  ::v-deep .el-dialog {
    margin-right: 1%;
    width: 600px;
  }
}

.hawkeyeDialog {
  ::v-deep .el-dialog {
    margin-left: 1%;
    margin-top: 150px;
  }
}
</style>
