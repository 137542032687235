<template>
  <div>
    <div id="myEcharts" ref="chart"></div>
  </div>
</template>

<script>
export default {
  props: ['data', 'color', 'type'],
  data () {
    return {
    }
  },
  created () {
    setTimeout(() => {
      this.drawEcharts()
    }, 300)
  },
  methods: {
    drawEcharts () {
      // 基于准备好的dom，初始化echarts实例
      const option = {
        backgroundColor: 'transparent',
        tooltip: {},
        xAxis: {
          data: this.type,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#00C5D4'
            }
          }
        },
        yAxis: {
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        grid: {
          top: 0,
          bottom: 18,
          right: 0,
          left: 0
        },
        series: [{
          name: '',
          type: 'pictorialBar',
          symbolSize: [20, 10],
          symbolOffset: [0, -5],
          tooltip: {
            show: false
          },
          z: 12,
          itemStyle: {
            normal: {
              color: '#14b1eb'
            }
          },
          data: [{
            value: this.data[0],
            itemStyle: {
              color: this.color[0]
            },
            symbolPosition: 'end'
          }, {
            value: this.data[1],
            itemStyle: {
              color: this.color[1]
            },
            symbolPosition: 'end'
          }, {
            value: this.data[2],
            itemStyle: {
              color: this.color[2]
            },
            symbolPosition: 'end'
          }]
        }, {
          name: '',
          type: 'pictorialBar',
          symbolSize: [20, 10],
          symbolOffset: [0, 7],
          tooltip: {
            show: false
          },
          z: 12,
          itemStyle: {
            normal: {
              color: '#14b1eb'
            }
          },
          data: [{
            value: 100,
            itemStyle: {
              color: this.color[0]
            }
          }, {
            value: 50,
            itemStyle: {
              color: this.color[1]
            }
          }, {
            value: 20,
            itemStyle: {
              color: this.color[2]
            }
          }]
        }, {
          name: '',
          type: 'pictorialBar',
          symbolSize: [30, 15],
          symbolOffset: [0, 10],
          tooltip: {
            show: false
          },
          z: 11,
          itemStyle: {
            normal: {
              color: 'transparent',
              borderColor: '#14b1eb',
              borderWidth: 2
            }
          },
          data: [{
            value: 100,
            itemStyle: {
              borderColor: this.color[0]
            }
          }, {
            value: 50,
            itemStyle: {
              borderColor: this.color[1]
            }
          }, {
            value: 20,
            itemStyle: {
              borderColor: this.color[2]
            }
          }]
        }, {
          name: '',
          type: 'pictorialBar',
          symbolSize: [40, 20],
          symbolOffset: [0, 13],
          tooltip: {
            show: false
          },
          z: 10,
          itemStyle: {
            normal: {
              color: 'transparent',
              borderColor: '#14b1eb',
              borderType: 'dashed',
              borderWidth: 3
            }
          },
          data: [{
            value: 100,
            itemStyle: {
              borderColor: this.color[0]
            }
          }, {
            value: 50,
            itemStyle: {
              borderColor: this.color[1]
            }
          }, {
            value: 20,
            itemStyle: {
              borderColor: this.color[2]
            }
          }]
        }, {
          stack: '1',
          type: 'bar',
          itemStyle: {
            normal: {
              color: '#14b1eb',
              opacity: 0.7
            }
          },
          label: {
            show: true,
            position: 'top'
          },
          silent: true,
          barWidth: 20,
          barGap: '30%', // Make series be overlap
          data: [{
            index:0,
            value: this.data[0],
            itemStyle: {
              color: this.color[0]
            }
          }, {
            index:1,
            value: this.data[1],
            itemStyle: {
              color: this.color[1]
            }
          }, {
            index:2,
            value: this.data[2],
            itemStyle: {
              color: this.color[2]
            }
          }]
        }]
      }
      this.$nextTick(() => {
        const dome = this.$refs.chart
        const myChart = this.$echarts.init(dome)
        // 指定饼图中显示的数据格式
        // 绘制图表
        myChart.setOption(option)
        myChart.on('click', ({dataIndex})=>{
          this.$emit('itemClick',dataIndex)
        })
        window.addEventListener('resize', () => { myChart.resize() })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#myEcharts {
  width: 100%;
  height: 100%;
  > div {
    height: 100%;
  }
}
</style>
