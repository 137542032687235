<template>
  <el-dialog class="dialog" v-bind="$attrs" lock-scroll width="80%" top="2%" @close="$emit('update:visible',false)">
    <div class="container" v-loading="loading" element-loading-text="数据导出中" element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)" style="height: 520px" >
      <div class="header" style="justify-content: space-between">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-popover placement="right" width="800" v-model="searchShow">
              <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item label="单位名称">
                  <el-input v-model="searchForm.unitName" autocomplete="off" size="small" placeholder="请输入" clearable
                            style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="设备特征码">
                  <el-input v-model="searchForm.equipmentCode" autocomplete="off" size="small" placeholder="请输入"
                            clearable
                            style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="设备类型">
                  <el-select v-model="searchForm.typeId" placeholder="请选择" size="small" clearable style="width:150px">
                    <el-option v-for="(item,i) in typeList" :key="i" :label="item.typeName" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <!--              <el-form-item label="状态">-->
                <!--                <el-select v-model="searchForm.status" placeholder="请选择" size="small" clearable style="width:100px">-->
                <!--                  <el-option v-for="(item,i) in [{id:1,label:'在线'},{id:0,label:'离线'}]" :key="i" :label="item.label"-->
                <!--                             :value="item.id">-->
                <!--                  </el-option>-->
                <!--                </el-select>-->
                <!--              </el-form-item>-->
                <el-form-item label="行政区域">
                  <el-cascader :disabled="isLoad" v-model="areaCodes" size="small" placeholder="请选择" :options="areaList"
                               :props="{ checkStrictly: true }" clearable style="width:150px"></el-cascader>
                </el-form-item>
                <el-form-item label="时间">
                  <el-date-picker v-model="searchForm.date" type="daterange" range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期" size="small">
                  </el-date-picker>
                </el-form-item>
                <!--              <el-form-item label="设备型号">-->
                <!--                <el-select v-model="searchForm.modelId" placeholder="请选择设备型号" size="small" clearable filterable remote-->
                <!--                           :remote-method="remoteMethod3">-->
                <!--                  <el-option v-for="(item,i) in modelOptions" :key="i" :label="item.deviceType" :value="item.id">-->
                <!--                    <span style="float: left">{{ item.deviceType }}</span>-->
                <!--                    <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{-->
                <!--                        item.deviceCodeName-->
                <!--                      }}</span>-->
                <!--                  </el-option>-->
                <!--                </el-select>-->
                <!--              </el-form-item>-->
                <!--              <el-form-item label="是否同步">-->
                <!--                <el-select v-model="searchForm.syncFlag" placeholder="请选择" clearable size="small" style="width:100px">-->
                <!--                  <el-option label="已同步" :value="1"></el-option>-->
                <!--                  <el-option label="未同步" :value="0"></el-option>-->
                <!--                </el-select>-->
                <!--              </el-form-item>-->
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" @click="searchShow = false">取消</el-button>
                <el-button type="primary" size="mini" @click="initTable">查询</el-button>
              </div>
              <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
            </el-popover>
          </el-form-item>
        </el-form>
        <!--      <div style="padding-right: 20px">-->
        <!--        <span>离线设备：</span>-->
        <!--        <OfflineDeviceTotal/>-->
        <!--      </div>-->
      </div>
      <!-- 表格 -->
      <div class="content">
        <el-table :data="deviceList" stripe style="width: 100%" height="100%" v-loading="loading1">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-row :gutter="20">
                <el-col :span="12" :offset="0">
                  <el-form label-position="left" inline class="demo-table-expand">
                    <el-form-item label="设备类型" :label-width="formLabelWidth">
                      <span>{{ props.row.typeName }}</span>
                    </el-form-item>
                    <el-form-item label="uuid" :label-width="formLabelWidth">
                      <span>{{ props.row.uuid }}</span>
                    </el-form-item>
                    <el-form-item label="设备型号" :label-width="formLabelWidth">
                      <span>{{ props.row.modelName }}</span>
                    </el-form-item>
                    <el-form-item label="所在单位" :label-width="formLabelWidth">
                      <span>{{ props.row.unitName }}</span>
                    </el-form-item>
                    <el-form-item label="安装建筑物" :label-width="formLabelWidth">
                      <span>{{ props.row.installName }}</span>
                    </el-form-item>
                    <el-form-item label="联系电话" :label-width="formLabelWidth">
                      <span>{{ props.row.contactPhone }}</span>
                    </el-form-item>
                    <!--                  <el-form-item label="状态" :label-width="formLabelWidth">-->
                    <!--                    <el-tag type="success" size="normal" v-if="props.row.status===1&&props.row.secStatus===0">在线-->
                    <!--                    </el-tag>-->
                    <!--                    <el-tag type="success" size="normal" effect="dark"-->
                    <!--                            v-else-if="props.row.status===1&&props.row.secStatus===1">在线-->
                    <!--                    </el-tag>-->
                    <!--                    <el-tag type="danger" size="normal" v-else-if="props.row.status===0">离线</el-tag>-->
                    <!--                    <el-tag type="primary" size="normal" v-else-if="props.row.status===2">维保</el-tag>-->
                    <!--                    <el-tag type="info" size="normal" v-else-if="props.row.status===3">拆除</el-tag>-->
                    <!--                    <el-tag type="warning" size="normal" v-else-if="props.row.status===4">故障</el-tag>-->
                    <!--                  </el-form-item>-->
                    <el-form-item label="智赣主键" :label-width="formLabelWidth">
                      <span>{{ props.row.outerEquipmentId }}</span>
                    </el-form-item>
                    <el-form-item label="图片" :label-width="formLabelWidth">
                      <el-image :src="props.row.imageUrl" fit="fill" :lazy="true"
                                :preview-src-list="[props.row.imageUrl]"></el-image>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="12" :offset="0">
                  <el-form label-position="left" inline class="demo-table-expand">
                    <el-form-item label="安装时间" :label-width="formLabelWidth">
                      <span>{{ props.row.installTime|timeFormat }}</span>
                    </el-form-item>
                    <el-form-item label="通讯方式" :label-width="formLabelWidth">
                      <span>{{ props.row.communicateTypeName }}</span>
                    </el-form-item>
                    <el-form-item label="设备特征码" :label-width="formLabelWidth">
                      <span>{{ props.row.equipmentCode }}</span>
                    </el-form-item>
                    <el-form-item label="基站特征码" :label-width="formLabelWidth">
                      <span>{{ props.row.stationCode }}</span>
                    </el-form-item>
                    <el-form-item label="最近数据通讯时间" :label-width="formLabelWidth">
                      <span>{{ props.row.lastContactTime|timeFormat }}</span>
                    </el-form-item>
                    <el-form-item label="描述" :label-width="formLabelWidth">
                      <span>{{ props.row.description }}</span>
                    </el-form-item>
                    <el-form-item label="经纬度" :label-width="formLabelWidth">
                      <span>{{ props.row.lng }} # {{ props.row.lat }}</span>
                      <el-button type="text" size="default"
                                 @click="open('location',{lng:props.row.lng,lat:props.row.lat})"
                                 icon="el-icon-location"></el-button>
                    </el-form-item>
                    <el-form-item label="地址" :label-width="formLabelWidth">
                      <span>{{ props.row.address }}</span>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip width="100">
          </el-table-column>
          <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="open('station',scope.row.equipmentCode)" v-show="scope.row.typeId===10">
                {{ scope.row.typeName }}
              </el-button>
              <div v-show="scope.row.typeId!==10">{{ scope.row.typeName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="unitName" label="所在单位" show-overflow-tooltip>
<!--            <template slot-scope="scope">-->
<!--              <el-button type="text" @click="showCompanyDialog(scope.row.unitId)">{{ scope.row.unitName }}</el-button>-->
<!--            </template>-->
          </el-table-column>
          <el-table-column prop="unitTypeName" label="监管类别" show-overflow-tooltip width="100">
          </el-table-column>
          <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
          </el-table-column>
          <!--        <el-table-column prop="lastContactTime" label="状态" show-overflow-tooltip width="80">-->
          <!--          <template slot-scope="props">-->
          <!--            <el-tag type="success" size="normal" v-if="props.row.status===1&&props.row.secStatus===0">在线</el-tag>-->
          <!--            <el-tag type="success" size="normal" effect="dark"-->
          <!--                    v-else-if="props.row.status===1&&props.row.secStatus===1">在线-->
          <!--            </el-tag>-->
          <!--            <el-tag type="danger" size="normal" v-else-if="props.row.status===0">离线</el-tag>-->
          <!--            <el-tag type="primary" size="normal" v-else-if="props.row.status===2">维保</el-tag>-->
          <!--            <el-tag type="info" size="normal" v-else-if="props.row.status===3">拆除</el-tag>-->
          <!--            <el-tag type="warning" size="normal" v-else-if="props.row.status===4">故障</el-tag>-->
          <!--          </template>-->
          <!--        </el-table-column>-->
          <el-table-column prop="contactPhone" label="联系电话" show-overflow-tooltip width="120" align="center">
          </el-table-column>
          <el-table-column label="经纬度">
            <template slot-scope="scope">
              <span>{{ scope.row.lng.toFixed(3) }}#{{ scope.row.lat.toFixed(3) }}</span>
              <el-button type="text" icon="el-icon-location" size="default"
                         @click="open('location',{lng:scope.row.lng,lat:scope.row.lat})"></el-button>
            </template>
          </el-table-column>
          <el-table-column prop="sysFlag" label="同步状态" show-overflow-tooltip width="100">
            <template slot-scope="scope">
              <el-tag type="danger" size="small" v-show="scope.row.syncFlag!==1">未同步</el-tag>
              <el-tag type="success" size="small" v-show="scope.row.syncFlag===1">已同步</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-popover placement="top" trigger="click">
                <el-tooltip class="item" effect="dark" content="修改" placement="top">
                  <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle
                             v-role="'device:edit'"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                  <el-button type="danger" @click="deleteDevice(scope.row.id)" size="mini" icon="el-icon-delete-solid"
                             circle v-role="'device:del'"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="查看告警记录" placement="top">
                  <el-button type="warning" @click="open('warning',scope.row.id)" size="mini" icon="el-icon-warning"
                             circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="查看心跳记录" placement="top">
                  <el-button type="danger" @click="open('heart',scope.row.id)" size="mini"
                             circle>
                    <svg-icon iconClass="heart" />
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="设备模拟量" placement="top">
                  <el-button type="primary" @click="open('anlong',scope.row.id)" size="mini" icon="el-icon-search"
                             circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="设备同步" placement="top">
                  <el-button type="success" @click="setDeviceSync(scope.row.id)" size="mini" icon="el-icon-refresh"
                             circle
                             v-show="scope.row.syncFlag!==1"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="强制同步" placement="top">
                  <el-button type="danger" @click="setDeviceSync(scope.row.id)" size="mini" icon="el-icon-refresh"
                             circle
                             v-show="scope.row.syncFlag===1"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="设置为维保" placement="top">
                  <el-button type="warning" @click="setDeviceStatus(scope.row.id,2)" size="mini" icon="el-icon-warning"
                             circle v-show="scope.row.status===1"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="设置为在线" placement="top">
                  <el-button type="success" @click="setDeviceStatus(scope.row.id,1)" size="mini" icon="el-icon-success"
                             circle v-show="scope.row.status===2"></el-button>
                </el-tooltip>
                <el-button type="primary" circle size="small" slot="reference" icon="el-icon-setting"></el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
      <!-- 设备表单弹窗 -->
      <el-dialog :title="optFlag?'添加设备':'修改设备'" :visible.sync="deviceShow" width="55%" @close="clear" top="3%"
                 v-dialogDrag :close-on-click-modal="false">
        <el-form :model="deviceForm" ref="deviceForm" :rules="rules">
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item label="设备类型" prop="typeId" :label-width="formLabelWidth2">
                <el-select v-model="deviceForm.typeId" placeholder="请选择设备类型" clearable style="float:left">
                  <el-option v-for="item in typeList" :key="item.value" :label="item.typeName" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="地址" prop="address" :label-width="formLabelWidth">
                <el-input v-model="deviceForm.address" autocomplete="off" placeholder="请输入单位地址"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item label="所在单位" :label-width="formLabelWidth2" prop="unitId">
                <!-- <el-autocomplete class="inline-input" v-model="deviceForm.unitName" value-key="unitName" :fetch-suggestions="querySearch" placeholder="请输入所在单位" :trigger-on-focus="false" @select="handleSelect"></el-autocomplete> -->
                <el-select v-model="deviceForm.unitId" ref="selectUnit" placeholder="搜素选择所在单位" clearable filterable
                           remote
                           :remote-method="remoteMethod" style="float:left">
                  <el-option v-for="(item,i) in unitOptions" :key="i" :label="item.unitName" :value="item.id">
                    <span style="float: left">{{ item.unitName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{
                        item.address
                      }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="安装建筑物" :label-width="formLabelWidth" prop="installId">
                <!-- <el-autocomplete class="inline-input" v-model="deviceForm.installName" value-key="buildingName" :fetch-suggestions="querySearch1" placeholder="请输入安装建筑物" :trigger-on-focus="false" @select="handleSelect1"></el-autocomplete> -->
                <el-select v-model="deviceForm.installId" placeholder="搜素选择安装建筑物" clearable filterable remote
                           :remote-method="remoteMethod2" style="float:left">
                  <el-option v-for="(item,i) in buildingOptions" :key="i" :label="item.buildingName" :value="item.id">
                    <span style="float: left">{{ item.buildingName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{
                        item.address
                      }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <!--          <el-col :span="12" :offset="0">-->
            <!--            <el-form-item label="所在消防部门" prop="fireUnitId" :label-width="formLabelWidth2">-->
            <!--              <el-select v-model="deviceForm.fireUnitId" placeholder="请选择所在消防部门" clearable filterable style="float:left">-->
            <!--                <el-option v-for="item in fireUnitList" :key="item.id" :label="item.label" :value="item.value">-->
            <!--                </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
            <!--          </el-col>-->
            <el-col :span="12" :offset="0">
              <el-form-item label="设备特征码" prop="equipmentCode" :label-width="formLabelWidth">
                <el-input v-model="deviceForm.equipmentCode" autocomplete="off" placeholder="请输入设备特征码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item label="设备型号" prop="modelId" :label-width="formLabelWidth2">
                <el-select v-model="deviceForm.modelId" placeholder="请选择设备型号" clearable filterable remote
                           :remote-method="remoteMethod3" style="float:left">
                  <el-option v-for="(item,i) in modelOptions" :key="i" :label="item.deviceType" :value="item.id">
                    <span style="float: left">{{ item.deviceType }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{
                        item.deviceCodeName
                      }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="通讯方式" prop="communicateType" :label-width="formLabelWidth">
                <el-select v-model="deviceForm.communicateType" placeholder="请选择通讯方式" clearable style="float:left">
                  <el-option v-for="item in commModeList" :key="item.value" :label="item.text" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item label="经纬度" :label-width="formLabelWidth2">
                <el-row :gutter="20">
                  <el-col :span="9" :offset="0">
                    <el-form-item prop="lng">
                      <el-input clearable v-model.number="deviceForm.lng" autocomplete="off" placeholder="经度"
                                disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1" :offset="0">
                    #
                  </el-col>
                  <el-col :span="9" :offset="0">
                    <el-form-item prop="lat">
                      <el-input clearable v-model.number="deviceForm.lat" autocomplete="off" placeholder="纬度"
                                disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3" :offset="0" style="padding-left:0">
                    <el-form-item>
                      <el-tooltip class="item" effect="dark" content="定位" placement="top">
                        <el-button type="text" style="font-size:16px" size="default" @click="open('map')"
                                   icon="el-icon-location"></el-button>
                      </el-tooltip>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="基站特征码" prop="stationCode" :label-width="formLabelWidth">
                <el-input v-model="deviceForm.stationCode" autocomplete="off" placeholder="请输入基站特征码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item label="安装时间" prop="installTime" :label-width="formLabelWidth2">
                <el-date-picker v-model="deviceForm.installTime" type="datetime" placeholder="选择安装时间"
                                style="float:left">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="最近数据通讯时间" prop="lastContactTime" :label-width="formLabelWidth">
                <el-date-picker v-model="deviceForm.lastContactTime" type="datetime" size="normal"
                                placeholder="选择最近数据通讯时间" style="float:left">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item label="权重" prop="weight" :label-width="formLabelWidth2">
                <el-input-number v-model="deviceForm.weight" size="normal">
                </el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="联系电话" prop="contactPhone" :label-width="formLabelWidth">
                <el-tooltip effect="light" content="多个号码请使用逗号（英文半角）分隔" placement="top">
                  <el-input v-model="deviceForm.contactPhone" autocomplete="off"
                            placeholder="不填通知将发送至法人联系号码"></el-input>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item label="智赣主键" prop="outerSystemId" :label-width="formLabelWidth2">
                <el-input v-model="deviceForm.outerEquipmentId" autocomplete="off" placeholder="请输入智赣主键"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0" v-if="deviceForm.typeId==8">
              <el-form-item label="摄像头播放方式" prop="cameraLinkType" :label-width="formLabelWidth">
                <el-select v-model="deviceForm.cameraLinkType" placeholder="摄像头播放方式" clearable style="float:left">
                  <el-option v-for="item in cameraLinkTypeList" :key="item.id" :label="item.text" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="deviceForm.typeId===8">
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <el-form-item label="摄像头功能类型" prop="cameraFuncType" :label-width="formLabelWidth2">
                  <el-select v-model="deviceForm.cameraFuncType" placeholder="请选择摄像头功能类型" clearable style="float:left">
                    <el-option v-for="item in cameraFuncTypeList" :key="item.id" :label="item.text" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form-item label="摄像头安装位置" prop="cameraInstallLocation" :label-width="formLabelWidth">
                  <el-select v-model="deviceForm.cameraInstallLocation" placeholder="摄像头安装位置" clearable
                             style="float:left">
                    <el-option v-for="item in cameraLocationList" :key="item.id" :label="item.text" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="15" :offset="0">
                <el-form-item label="安防平台监控点编码" prop="cameraCode" label-width="150px">
                  <el-input v-model="deviceForm.cameraCode" autocomplete="off" placeholder="请输入安防平台监控点编码"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <el-row :gutter="20">
            <el-col :span="20" :offset="0">
              <el-form-item label="描述" prop="description" :label-width="formLabelWidth2">
                <el-input type="textarea" v-model="deviceForm.description" autocomplete="off"
                          placeholder="请输入描述"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20" :offset="0">
              <el-form-item label="设备主照片" prop="unitImage" :label-width="formLabelWidth2">
                <el-upload ref="upload" :file-list="fileList" :headers="{Authorization:token}"
                           action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :on-success="getDeviceImage"
                           :on-error="error" :limit="1" list-type="picture-card" :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog title="设备图片" :visible.sync="dialogVisible" :append-to-body="true" top="0">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="dialog-footer">
          <el-button @click="deviceShow = false">取 消</el-button>
          <el-button type="primary" @click="addDevice" v-if="optFlag">添 加</el-button>
          <el-button type="primary" @click="updateDevice" v-if="!optFlag">修 改</el-button>
        </div>
      </el-dialog>
      <!-- 地图 -->
      <el-dialog class="dialog" title="地图" :visible.sync="mapShow" width="50%" top="5%" :close-on-click-modal="false">
        <div class="map">
          <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" style="height:500px" :zoom="zoom"
                     ak="ahVb0clSVLITXPpNieRI7DXL47tgHvcE" @ready="handler" @click="setPosition">
            <bm-view style="width: 100%; height:100px; flex: 1"></bm-view>
            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true"
                            :autoLocation="true"></bm-geolocation>
            <bm-marker :position="location" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
            </bm-marker>
          </baidu-map>
        </div>
        <div class="dialog-footer" v-show="optFlag">
          <el-button @click="mapShow = false">取 消</el-button>
          <el-button type="primary" @click="getPosition">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 设备告警记录 -->
      <el-drawer title="告警记录" :visible.sync="warningShow" direction="rtl" size="30%" :before-close="handleClose">
        <el-timeline>
          <el-timeline-item :timestamp="item.alarmDate|timeFormat" placement="top" v-for="(item,i) in warningList"
                            :key="i">
            <el-card>
              <h4>{{ item.content }}</h4>
              <p>
                <el-tag type="danger" size="small" v-show="item.status===0">发生</el-tag>
                <el-tag type="primary" size="small" v-show="item.status===1">已派警</el-tag>
                <el-tag type="success" size="small" v-show="item.status===2">已出警</el-tag>
                <el-tag type="success" size="small" v-show="item.status===-1">已结束</el-tag>
              </p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </el-drawer>
      <!-- 心跳记录 -->
      <el-drawer title="心跳记录" :visible.sync="heart.visible" direction="rtl" size="30%">
        <div
          v-loading="heart.loading"
          style="height: 100%;width:100%;">
          <div v-if="heart.list.length===0 && !heart.loading"
               style="display: flex;align-items: center;justify-content: center;height: 100%;width:100%;">暂无数据
          </div>
          <el-timeline v-else>
            <el-timeline-item :timestamp="item.updateTime|timeFormat" placement="top" v-for="(item,i) in heart.list"
                              :key="i">
              <el-card>
                <h4>{{ item.describes }}</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-drawer>
      <!-- 设备模拟量 -->
      <el-dialog title="设备模拟量" :visible.sync="anlongShow" width="70%" top="1%">
        <div class="header">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-button type="success" size="small" icon="el-icon-plus" @click="open('addAnlong')">新增探测器</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="warning" size="small" icon="el-icon-refresh" @click="syncAllData">一键同步至省平台</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 表格 -->
        <div class="content">
          <el-table :data="anlongList" stripe style="width: 100%" height="500px" v-loading="loading2">
            <!--          <el-table-column prop="uuid" label="uuid" show-overflow-tooltip width="100">-->
            <!--          </el-table-column>-->
            <!--          <el-table-column prop="equipmentId" label="设备ID" show-overflow-tooltip width="80">-->
            <!--          </el-table-column>-->
            <el-table-column prop="jcqTypeName" label="数值类型" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="currentValue" label="数值" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="jcqTypeUnit" label="单位" show-overflow-tooltip>
            </el-table-column>
            <!--          <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip>-->
            <!--            <template slot-scope="scope">-->
            <!--              {{ scope.row.createTime|timeFormat }}-->
            <!--            </template>-->
            <!--          </el-table-column>-->
            <el-table-column prop="updateTime" label="修改时间" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.updateTime|timeFormat }}
              </template>
            </el-table-column>
            <el-table-column label="同步状态" show-overflow-tooltip width="90">
              <template slot-scope="scope">
                <el-tag type="success" size="small" v-if="!!scope.row.outerJcqId">已同步</el-tag>
                <el-tag type="danger" size="small" v-else>未同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150" align="center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="修改" placement="top">
                  <el-button size="mini" @click="open('editAnlong',scope.row)" type="warning" icon="el-icon-edit"
                             circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="同步" placement="top">
                  <el-button size="mini" @click="syncSingleData(scope.row.id)" type="warning" icon="el-icon-refresh"
                             circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                  <el-button type="danger" @click="deleteAnlong(scope.row.id)" size="mini" icon="el-icon-delete-solid"
                             circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <el-pagination @current-change="handleAnlongChange" :current-page.sync="anlongPage" :page-size="10"
                         layout="total, prev, pager, next, jumper" :total="anlongTotal">
          </el-pagination>
        </div>
      </el-dialog>
      <el-dialog :title="optFlag3?'添加模拟量':'修改模拟量'" :visible.sync="anlongFormShow" width="30%" top="5%" v-dialogDrag
                 :close-on-click-modal="false">
        <el-form :model="anlong" ref="anlongForm">
          <el-form-item label="模拟量类型" :label-width="formLabelWidth2">
            <el-select v-model="anlong.jcqTypeId" placeholder="请选择模拟量类型" clearable style="float:left">
              <el-option v-for="(item,i) in jcqTypeList" :key="i" :label="item.text" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="当前值" :label-width="formLabelWidth2">
            <el-input v-model.trim="anlong.currentValue" size="normal" clearable style="float: left">
              <template v-if="currJcqUnit" slot="append">{{ currJcqUnit }}</template>
            </el-input>
          </el-form-item>

          <el-form-item label="报警上限" :label-width="formLabelWidth2">
            <el-input v-model="anlong.alarmUpper" size="normal" clearable></el-input>
          </el-form-item>
          <el-form-item label="报警下限" :label-width="formLabelWidth2">
            <el-input v-model="anlong.alarmLower" size="normal" clearable></el-input>
          </el-form-item>
          <el-form-item label="量程上限" :label-width="formLabelWidth2">
            <el-input v-model="anlong.rangeUpper" size="normal" clearable></el-input>
          </el-form-item>
          <el-form-item label="量程下限" :label-width="formLabelWidth2">
            <el-input v-model="anlong.rangeLower" size="normal" clearable></el-input>
          </el-form-item>

          <!--        <template v-if="!optFlag3">-->
          <!--          <el-form-item label="探测器智赣主键" :label-width="formLabelWidth2">-->
          <!--            <el-input v-model="anlong.outerTcqId" placeholder="请输入当前值" size="normal" clearable></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="模拟量智赣主键" :label-width="formLabelWidth2">-->
          <!--            <el-input v-model="anlong.outerJcqId" placeholder="请输入当前值" size="normal" clearable></el-input>-->
          <!--          </el-form-item>-->
          <!--        </template>-->

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="anlongFormShow = false">取 消</el-button>
          <el-button type="primary" @click="addAnlong" v-if="optFlag3">添 加</el-button>
          <el-button type="primary" @click="updateAnlong" v-if="!optFlag3">修 改</el-button>
        </div>
      </el-dialog>
      <el-dialog title="基站绑定设备" :visible.sync="stationShow" width="70%" top="3%">
        <div class="header">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-popover placement="right" width="600" v-model="searchStationShow">
                <el-form :inline="true" :model="searchStation" class="demo-form-inline">
                  <el-form-item label="单位名称">
                    <el-input v-model="searchStation.unitName" autocomplete="off" size="small" placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="设备特征码">
                    <el-input v-model="searchStation.equipmentCode" autocomplete="off" size="small" placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="设备类型">
                    <el-select v-model="searchStation.typeId" placeholder="请选择" size="small" clearable>
                      <el-option v-for="(item,i) in typeList" :key="i" :label="item.typeName" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="状态">
                    <el-select v-model="searchStation.status" placeholder="请选择" size="small" clearable>
                      <el-option v-for="(item,i) in [{id:1,label:'在线'},{id:0,label:'离线'}]" :key="i" :label="item.label"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" @click="searchStationShow = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="initStationDevice">查询</el-button>
                </div>
                <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
              </el-popover>
            </el-form-item>
          </el-form>
        </div>
        <!-- 表格 -->
        <div class="content">
          <el-table :data="stationDeviceList" stripe style="width: 100%" height="500" v-loading="stationLoading">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-row :gutter="20">
                  <el-col :span="12" :offset="0">
                    <el-form label-position="left" inline class="demo-table-expand">
                      <el-form-item label="设备类型" :label-width="formLabelWidth">
                        <span>{{ props.row.typeName }}</span>
                      </el-form-item>
                      <el-form-item label="设备型号" :label-width="formLabelWidth">
                        <span>{{ props.row.modelName }}</span>
                      </el-form-item>
                      <el-form-item label="所在单位" :label-width="formLabelWidth">
                        <span>{{ props.row.unitName }}</span>
                      </el-form-item>
                      <el-form-item label="安装建筑物" :label-width="formLabelWidth">
                        <span>{{ props.row.installName }}</span>
                      </el-form-item>
                      <el-form-item label="通讯方式" :label-width="formLabelWidth">
                        <span>{{ props.row.communicateTypeName }}</span>
                      </el-form-item>
                      <el-form-item label="联系电话" :label-width="formLabelWidth">
                        <span>{{ props.row.contactPhone }}</span>
                      </el-form-item>
                      <el-form-item label="状态" :label-width="formLabelWidth">
                        <el-tag type="success" size="normal" v-show="props.row.status===1">在线</el-tag>
                        <el-tag type="danger" size="normal" v-show="props.row.status===0">离线</el-tag>
                      </el-form-item>
                      <el-form-item label="图片" :label-width="formLabelWidth">
                        <el-image :src="props.row.imageUrl" fit="fill" :lazy="true"
                                  :preview-src-list="[props.row.imageUrl]"></el-image>
                      </el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="12" :offset="0">
                    <el-form label-position="left" inline class="demo-table-expand">
                      <el-form-item label="uuid" :label-width="formLabelWidth">
                        <span>{{ props.row.uuid }}</span>
                      </el-form-item>
                      <el-form-item label="安装时间" :label-width="formLabelWidth">
                        <span>{{ props.row.installTime|timeFormat }}</span>
                      </el-form-item>
                      <el-form-item label="设备特征码" :label-width="formLabelWidth">
                        <span>{{ props.row.equipmentCode }}</span>
                      </el-form-item>
                      <el-form-item label="基站特征码" :label-width="formLabelWidth">
                        <span>{{ props.row.stationCode }}</span>
                      </el-form-item>
                      <el-form-item label="最近数据通讯时间" :label-width="formLabelWidth">
                        <span>{{ props.row.lastContactTime|timeFormat }}</span>
                      </el-form-item>
                      <el-form-item label="描述" :label-width="formLabelWidth">
                        <span>{{ props.row.description }}</span>
                      </el-form-item>
                      <el-form-item label="经纬度" :label-width="formLabelWidth">
                        <span>{{ props.row.lng }} # {{ props.row.lat }}</span>
                        <el-button type="text" size="default"
                                   @click="open('location',{lng:props.row.lng,lat:props.row.lat})"
                                   icon="el-icon-location"></el-button>
                      </el-form-item>
                      <el-form-item label="地址" :label-width="formLabelWidth">
                        <span>{{ props.row.address }}</span>
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip width="80">
            </el-table-column>
            <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip width="80">
            </el-table-column>
            <el-table-column prop="unitName" label="所在单位" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="unitTypeName" label="监管类别" show-overflow-tooltip width="100">
            </el-table-column>
            <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="lastContactTime" label="状态" show-overflow-tooltip width="80">
              <template slot-scope="props">
                <el-tag type="success" size="normal" v-show="props.row.status===1">在线</el-tag>
                <el-tag type="danger" size="normal" v-show="props.row.status===0">离线</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="contactPhone" label="联系电话" show-overflow-tooltip width="120">
            </el-table-column>
            <el-table-column prop="installTime" label="安装时间" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.installTime|timeFormat }}
              </template>
            </el-table-column>
            <el-table-column label="经纬度" width="140">
              <template slot-scope="scope">
                <span>{{ scope.row.lng.toFixed(3) }}#{{ scope.row.lat.toFixed(3) }}</span>
                <el-button type="text" icon="el-icon-location" size="default"
                           @click="open('location',{lng:scope.row.lng,lat:scope.row.lat})"></el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="解绑" placement="top">
                  <el-button size="mini" @click="unbind(scope.row.equipmentCode)" type="warning" icon="el-icon-unlock"
                             circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </el-dialog>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import _ from 'lodash'
// import OfflineDeviceTotal from "./components/OfflineDeviceTotal"
export default {
  components: {
    BaiduMap,
    BmView,
    BmGeolocation,
    BmMarker,
    // OfflineDeviceTotal
  },
  data() {
    const validateContactPhone = (rule, value, callback) => {
      if (value === '') {
        return callback()
      }
      const regx = /^(1\d{10},?)*$/
      //\d{11}
      if (!regx.test(value)) {
        callback(new Error('输入格式有误'));
      }
      else {
        callback()
      }
    };
    return {
      location: {
        lng: 0,
        lat: 0
      },
      zoom: 18,
      addressKeyword: '',
      stationCode: '',
      currentPage: 1,
      formLabelWidth: '130px',
      formLabelWidth2: '120px',
      total: 0,
      deviceList: [],
      anlongPage: 1,
      anlongTotal: 0,
      anlongList: [],
      warningList: [],
      stationDeviceList: [],
      areaList: [],
      fileList: [],
      deviceId: 0, // 单位ID
      anlongId: 0, // 模拟量ID
      deviceForm: {
        deviceName: '',
        address: '',
        area: '',
        floorNum: 1,
        areaCodes: [],
        isTall: '',
        natureId: '',
        structureId: '',
        lng: '',
        lat: '',
        unitNum: 1,
        managerName: '',
        managerPhone: '',
        localPolice: '',
        policePhone: '',
        remark: '',
        modelId: '',
        communicateType: ''
      }, // 建筑表单
      deviceFormCopy: {},
      anlong: {},
      typeList: [],
      fireUnitList: [],
      commModeList: [],
      jcqTypeList: [],
      cameraFuncTypeList: [],
      cameraLinkTypeList: [],
      cameraLocationList: [],
      rules: {
        typeId: [
          {required: true, message: '请选择设备类型', trigger: 'change'}
        ],
        modelId: [
          {required: true, message: '请选择设备型号', trigger: 'change'}
        ],
        unitId: [
          {required: true, message: '请选择所在单位', trigger: 'change'}
        ],
        equipmentCode: [
          {required: true, message: '请输入设备特征码', trigger: 'blur'}
        ],
        cameraFuncType: [
          {required: true, message: '请选择摄像头功能类型', trigger: 'change'}
        ],
        cameraInstallLocation: [
          {required: true, message: '请选择摄像头安装位置', trigger: 'change'}
        ],
        cameraLinkType: [
          {required: true, message: '请选择摄像头播放方式', trigger: 'change'}
        ],
        contactPhone: [
          {validator: validateContactPhone}
        ]
      },
      searchForm: {
        date: []
      },
      searchStation: {},
      deviceShow: false,
      mapShow: false,
      optFlag: false,
      optFlag2: false,
      optFlag3: false,
      isLoad: false,
      unitOptions: [],
      buildingOptions: [],
      modelOptions: [],
      areaCodes: [],
      loading: false,
      loading1: false,
      loading2: false,
      stationLoading: false,
      warningShow: false,
      anlongShow: false,
      anlongFormShow: false,
      searchShow: false,
      searchStationShow: false,
      stationShow: false,
      token: '',
      dialogImageUrl: '',
      dialogVisible: false,
      heart: {
        visible: false,
        loading: true,
        list: [],
        cacheId: null
      }
    }
  },
  computed: {
    currJcqUnit() {
      const item = this.jcqTypeList.find(v => v.id === this.anlong.jcqTypeId)
      if (!!item) return item.memo
      return false
    }
  },
  created() {
    this.initTable()
    this.getTypeList()
    this.getAreaList()
    // this.getUnitList()
    // this.getBuildingList()
    this.getFireUnitList()
    this.getCommModeList()
    this.getJcqTypeList()
    this.getCameraFuncTypeList()
    this.getCameraLocationList()
    this.getCameraLinkTypeList()
    this.deviceFormCopy = JSON.stringify(this.deviceForm)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    showCompanyDialog(unitId) {
      this.$refs.CompanyDetailDialog.show(unitId)
    },
    //同步搜索数据
    syncAllData() {
      this.$axios.post("/equipmentJcq/sync/" + this.deviceId).then((res) => {
        this.$message.success(res.data.msg)
        this.initAnlong()
      })
    },
    //同步单个数据
    syncSingleData(jcqId) {
      this.$axios.post("/equipmentJcq/syncById/" + jcqId).then((res) => {
        this.$message.success(res.data.msg)
        this.initAnlong()
      })
    },
    async handleCurrentChange(val) {
      this.currentPage = val
      this.initTable()
    },
    async handleAnlongChange(val) {
      this.anlongPage = val
      this.initAnlong()
    },
    // 表格初始化
    async initTable() {
      const data = {}
      if (this.searchForm.unitName) {
        data.unitName = this.searchForm.unitName
      }
      if (this.searchForm.typeId) {
        data.typeId = this.searchForm.typeId
      }
      if (this.searchForm.modelId) {
        data.modelId = this.searchForm.modelId
      }
      if (this.searchForm.equipmentCode) {
        data.equipmentCode = this.searchForm.equipmentCode
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      if (this.searchForm.date) {
        if (this.searchForm.date.length > 0) {
          data.startTime = this.searchForm.date[0]
          data.endTime = this.searchForm.date[1]
        }
      }
      data.status = 0
      data.syncFlag = this.searchForm.syncFlag
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading1 = true
      const {data: result} = await this.$axios.get('/equipment/list', {params: data})
      if (result.code === 200) {
        this.loading1 = false
        this.deviceList = result.data.result
        this.total = result.data.total
      }
      else {
        this.loading1 = false
        this.$message.error(result.msg)
      }
    },
    // 删除某条数据
    async deleteDevice(id) {
      const confirm = await this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.delete(`/equipment/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    updateDevice() {
      this.$refs.deviceForm.validate(async valid => {
        if (valid) {
          const {data: result} = await this.$axios.put(`/equipment/edit/${this.deviceId}`, this.deviceForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.deviceShow = false
            this.initTable()
          }
          else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 查看图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getDeviceImage(res, file, fileList) {
      if (res.code === 200) {
        this.deviceForm.imageUrl = res.data
      }
    },
    error(err, file, fileList) {
      this.$message.error(err)
    },
    // 新增设备
    addDevice: _.debounce(function () {
      this.$refs.deviceForm.validate(async valid => {
        if (valid) {
          const {data: result} = await this.$axios.post('/equipment/add', this.deviceForm)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.deviceShow = false
            this.initTable()
          }
          else {
            this.$message.error(result.msg)
          }
        }
      })
    }, 1000),
    async initAnlong() {
      const data = {
        pageSize: 10,
        pageNo: this.anlongPage,
        equipmentId: this.deviceId
      }
      this.loading2 = true
      const {data: result} = await this.$axios.get('/equipmentJcq/list', {params: data})
      if (result.code === 200) {
        this.anlongList = result.data.result
        this.anlongTotal = result.data.total
        this.loading2 = false
      }
    },
    // 删除某条数据
    async deleteAnlong(id) {
      const confirm = await this.$confirm('此操作将永久删除该模拟量, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.delete(`/equipmentJcq/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.anlongTotal - 1) / 10) // 总页数
          this.anlongPage = this.anlongPage > totalPage ? totalPage : this.anlongPage
          this.anlongPage = this.anlongPage < 1 ? 1 : this.anlongPage
          this.initAnlong()
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    async updateAnlong() {
      // this.$refs.deviceForm.validate(async valid => {
      //   if (valid) {
      this.anlong.equipmentId = this.deviceId
      const {data: result} = await this.$axios.put(`/equipmentJcq/edit/${this.anlongId}`, this.anlong)
      if (result.code === 200) {
        this.$message.success('修改成功')
        this.anlongFormShow = false
        this.initAnlong()
      }
      else {
        this.$message.error(result.msg)
      }
      //   }
      // })
    },
    // 新增培训
    async addAnlong() {
      // this.$refs.deviceForm.validate(async valid => {
      //   if (valid) {
      this.anlong.equipmentId = this.deviceId
      const {data: result} = await this.$axios.post('/equipmentJcq/addForWeb', this.anlong)
      if (result.code === 200) {
        this.$message.success('新增成功')
        const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
        this.anlongPage = this.anlongPage > totalPage ? totalPage : this.anlongPage
        this.anlongPage = this.anlongPage < 1 ? 1 : this.anlongPage
        this.anlongFormShow = false
        this.initAnlong()
      }
      else {
        this.$message.error(result.msg)
      }
      //   }
      // })
    },
    // 基站绑定设备列表
    async initStationDevice() {
      const data = {
        stationCode: this.stationCode
      }
      if (this.searchStation.unitName) {
        data.unitName = this.searchStation.unitName
      }
      if (this.searchStation.typeId) {
        data.typeId = this.searchStation.typeId
      }
      if (this.searchStation.equipmentCode) {
        data.searchStation = this.searchStation.equipmentCode
      }
      data.status = this.searchStation.status
      this.stationLoading = true
      const {data: result} = await this.$axios.get('/equipment/list', {params: data})
      if (result.code === 200) {
        this.stationLoading = false
        this.stationDeviceList = result.data
      }
      else {
        this.stationLoading = false
        this.$message.error(result.msg)
      }
    },
    // 解绑设备
    async unbind(equipmentCode) {
      const confirm = await this.$confirm('确认从该基站解绑此设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.post('/willUploadEquipment/unbindByEquipmentCode', {
          equipmentCode: equipmentCode,
          stationCode: this.stationCode
        })
        if (result.code === 200) {
          this.initStationDevice()
          this.$message.success('解绑成功')
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 导出Excel
    async exportExcel() {
      this.loading = true
      const data = {}
      if (this.searchForm.unitName) {
        data.unitName = this.searchForm.unitName
      }
      if (this.searchForm.typeId) {
        data.typeId = this.searchForm.typeId
      }
      if (this.searchForm.equipmentCode) {
        data.equipmentCode = this.searchForm.equipmentCode
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      if (this.searchForm.date) {
        if (this.searchForm.date.length > 0) {
          data.startTime = this.searchForm.date[0]
          data.endTime = this.searchForm.date[1]
        }
      }
      const result = await this.$axios.post('/util/exportEquipment', data, {responseType: 'blob'})
      if (result.data.type === 'application/json') {
        this.loading = false
        this.$message.error('数据为空,不能导出')
        return
      }
      if (!result.data.message) {
        this.loading = false
      }
      else {
        this.loading = false
        this.$message.error(result.data.message)
        return
      }
      const blob = new Blob([result.data], {type: 'application/octet-stream'})
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '设备信息.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      }
      else {
        navigator.msSaveBlob(blob, '设备信息.xlsx')
      }
    },
    // 获取位置
    async getPosition() {
      console.log(this.location)
      this.deviceForm.lng = this.location.lng
      this.deviceForm.lat = this.location.lat
      this.mapShow = false
    },
    // 设置位置
    async setPosition(e) {
      this.location.lng = e.point.lng
      this.location.lat = e.point.lat
    },
    // 设备同步
    async setDeviceSync(id) {
      const confirm = await this.$confirm('是否同步该设备至省平台?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.post(`/device/add/${id}`)
        if (result.code === 200) {
          this.initTable()
          this.$message.success('同步成功')
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 设置设备状态
    async setDeviceStatus(id, flag) {
      const confirm = await this.$confirm(`是否同步该设为${flag === 1 ? '在线' : '维保'}状态？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.put(`/equipment/editStatus/${id}`, {status: flag})
        if (result.code === 200) {
          this.initTable()
          this.$message.success('设置成功')
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 地图初始化
    handler({BMap, map}) {
      const that = this
      var geolocation = new BMap.Geolocation()
      geolocation.getCurrentPosition(function (r) {
        that.location.lng = r.point.lng
        that.location.lat = r.point.lat
      })
    },
    async open(flag, row) {
      switch (flag) {
        case 'add':
          this.deviceShow = true
          this.$nextTick(() => {
            this.$refs.deviceForm.clearValidate()
          })
          this.optFlag = true
          break
        case 'edit':
          console.log(row)
          this.optFlag = false
          this.unitOptions = []
          this.modelOptions = []
          this.deviceForm = JSON.parse(JSON.stringify(row))

          if (this.deviceForm.fireUnitId === 0) delete this.deviceForm.fireUnitId
          if (this.deviceForm.installId === 0) delete this.deviceForm.installId
          this.unitOptions.push({
            id: this.deviceForm.unitId,
            unitName: this.deviceForm.unitName
          })
          this.modelOptions.push({
            id: this.deviceForm.modelId,
            deviceType: this.deviceForm.modelName
          })
          if (!this.deviceForm.imageUrl) {
          }
          else {
            this.fileList = [{name: 'file', url: this.deviceForm.imageUrl}]
          }
          this.deviceId = row.id
          this.deviceShow = true
          this.$nextTick(() => {
            this.$refs.deviceForm.clearValidate()
          })
          break
        case 'map':
          this.mapShow = true
          this.optFlag2 = true
          break
        case 'location':
          this.mapShow = true
          this.optFlag2 = false
          this.location = row
          break
        case 'warning': {
          this.warningShow = true
          const {data: result} = await this.$axios.get('/alarm/record', {params: {equipmentId: row}})
          if (result.code === 200) {
            this.warningList = result.data
          }
        }
          break
        case 'heart':
          if (this.heart.cacheId !== row) {
            Object.assign(this.heart, {
              list: [],
              cacheId: row
            })
          }
          this.heart.loading = true
          this.heart.visible = true
          const {data: result} = await this.$axios.get('/equipment/getRuntimeInfo/' + row)
          if (result.code === 200) {
            Object.assign(this.heart, {
              loading: false,
              list: result.data
            })
          }
          break
        case 'anlong':
          this.deviceId = row
          this.anlongShow = true
          this.initAnlong()
          break
        case 'addAnlong':
          this.anlong = {
            alarmUpper: "100",
            alarmLower: "0",
            rangeUpper: "65535",
            rangeLower: "-100"
          }
          this.optFlag3 = true
          this.anlongFormShow = true
          break
        case 'editAnlong':
          this.anlong = JSON.parse(JSON.stringify(row))
          this.anlongId = row.id
          this.anlong.jcqTypeId = parseInt(row.jcqTypeId)
          this.optFlag3 = false
          this.anlongFormShow = true
          break
        case 'station':
          if (row) {
            this.stationCode = row
            this.initStationDevice()
            this.stationShow = true
          }
          else {
            this.$message.error('该基站暂无基站特征码')
          }
      }
    },
    // 查找消防部门
    async getFireUnitList() {
      const {data: result} = await this.$axios.get('/organization/list', {params: {parentId: 1}})
      if (result.code === 200) {
        this.fireUnitList = result.data
      }
    },
    // 查找设备类型
    async getTypeList() {
      const {data: result} = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      }
    },
    // 获取通讯方式
    async getCommModeList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'commMode'}})
      if (result.code === 200) {
        this.commModeList = result.data
      }
    },
    // 获取模拟量类型
    async getJcqTypeList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'jcqType'}})
      if (result.code === 200) {
        this.jcqTypeList = result.data
      }
    },
    // 获取摄像头功能类型
    async getCameraFuncTypeList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'cameraFuncType'}})
      if (result.code === 200) {
        this.cameraFuncTypeList = result.data
      }
    },
    // 获取摄像头安装位置
    async getCameraLocationList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'cameraInstallLocation'}})
      if (result.code === 200) {
        this.cameraLocationList = result.data
      }
    },
    // 获取摄像头播放方式
    async getCameraLinkTypeList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'cameraLinkType'}})
      if (result.code === 200) {
        this.cameraLinkTypeList = result.data
      }
    },
    // 查找行政区域
    async getAreaList() {
      const {data: result} = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        if (result.data.length === 0) {
          this.isLoad = true
          return
        }
        this.areaList = this.changeKey(result.data)
      }
      else {
        this.$message.error(result.msg)
      }
    },
    changeKey(array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              subItem.children.forEach((subItem2, index) => {
                [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
              })
            }
          })
        }
      })
      return array
    },
    // 关闭弹窗清空表单
    clear() {
      this.deviceForm = JSON.parse(this.deviceFormCopy)
      // this.$refs.deviceForm.resetFileds()
      this.$refs.upload.clearFiles()
      this.$refs.deviceForm.clearValidate()
    },
    async remoteMethod(query) {
      const {data: result} = await this.$axios.get('/unit/listAllUnit', {params: {unitName: query}})

      if (result.code === 200) {
        this.unitOptions = result.data

      }
    },
    async remoteMethod2(query) {
      const {data: result} = await this.$axios.get('/building/listAllBuilding', {params: {buildingName: query}})
      if (result.code === 200) {
        this.buildingOptions = result.data
      }
    },
    // 查找设备型号
    async remoteMethod3(query) {
      const {data: result} = await this.$axios.get('/equipmentModel/list', {params: {deviceType: query}})
      if (result.code === 200) {
        this.modelOptions = result.data
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done()
          })
          .catch(_ => {
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}

.map {
  width: 100%;
  height: 500px !important;

  .bmView {
    height: 100% !important;

    > div:first-child {
      height: 500px !important;
    }
  }
}

.demo-table-expand {
  font-size: 0;

  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;

    ::v-deep label {
      color: #99a9bf !important;
    }
  }
}

::v-deep .el-table__expanded-cell {
  padding: 0 50px;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.addr {
  font-size: 12px;
  color: #b4b4b4;
}

::v-deep :focus {
  outline: 0;
}

::v-deep .el-drawer__body {
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  overflow-y: scroll;

  p {
    margin-top: 10px;
  }

  .el-timeline-item__timestamp {
    color: #fff;
  }

  .el-timeline-item__content {
    .el-card {
      background-color: rgba(7, 104, 159, 0.1);
      border: 1px solid rgb(7, 104, 159);
      box-shadow: 0 0 10px #42affe inset;
      color: #fff;

      h3 {
        margin-bottom: 10px;

        i {
          margin-right: 3px;
        }
      }

      h3:last-chlid {
        margin-bottom: 0;
      }
    }
  }

  .el-timeline-item__tail {
    border: 1px solid rgb(66, 175, 254);
  }

  .el-timeline-item__node {
    background-color: rgb(66, 175, 254);
    box-shadow: 0 0 10px #42affe;
  }
}
</style>
