<template>
  <el-dialog v-dialog-drag title="故障列表" width="70%" lock-scroll :visible.sync="visible">
    <Current :extraParams="{alarmType:108}" style="height: 60vh"/>
  </el-dialog>
</template>

<script>
import Current from "@/views/warning/Current"

export default {
  name: "FaultDialog",
  components: {
    Current
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    open() {
      this.visible = true
    }
  }
}
</script>

<style scoped>

</style>
