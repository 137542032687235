<template>
  <div class="container_content">
    <div class="left_box" v-show="showLeft">
      <div class="search_box">
        <div>
          <div class="title"> {{ name }} </div>
          <el-input v-model="keyWord" placeholder="请输入名称进行搜索" clearable size="small" @input="search"></el-input>
        </div>
        <div v-if="name === '设备详情'">
          <el-select v-model="type" multiple collapse-tags style="margin-left: 20px;" placeholder="请选择设备类型" size="small" clearable @change="search">
            <el-option v-for="(item, index) in options" :key="index" :label="item.typeName" :value="item.id"></el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-table :data="list" height="100%" v-loading="loading1">
          <el-table-column prop="unitName" label="名称" show-overflow-tooltip v-if="name !== '建筑物' && name !== '水源'"> </el-table-column>
          <el-table-column prop="buildingName" label="名称" show-overflow-tooltip v-if="name === '建筑物'"> </el-table-column>
          <el-table-column label="名称" show-overflow-tooltip v-if="name === '水源'"> 消防栓 </el-table-column>
          <el-table-column prop="typeName" label="类型" show-overflow-tooltip v-if="name === '设备详情'"> </el-table-column>
          <el-table-column label="定位" width="60">
            <template slot-scope="scope">
              <div @click="pointTo(scope.row, 'localtion')" style="localtion"> <i class="el-icon-location" style="color:#409EFF"></i> </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination :pager-count="5" small @current-change="currentChange" :current-page.sync="pageNo" :page-size="pageSize" :page-sizes="[20, 50, 100, 200, 300]" @size-change="changSize" layout="prev, pager, next, sizes" :total="total" background>
        </el-pagination>
      </div>
    </div>
    <div class="right_box">
      <div class="map_box" v-loading="loading">
        <div class="video" v-if="showModal11">
          <el-button type="danger" @click="showModal11 = false" size="mini" class="btn"><i></i>关闭</el-button>
          <EZUIKit :videoUrl="videoUrl" />
        </div>
        <baidu-map class=" bmView" :scroll-wheel-zoom="true" @ready="handler">
        </baidu-map>
      </div>
      <div class="left_button" @click="showLeft = !showLeft">
        <i class="el-icon-d-arrow-left" v-show="showLeft"></i>
        <i class="el-icon-d-arrow-right" v-show="!showLeft"></i>
      </div>
      <div class="close" @click="close"> <i class="el-icon-close"></i> </div>
    </div>
  </div>
</template>

<script>
import EZUIKit from '../components/EZUIKit'
export default {
  props: {
    name: {
      type: String
    },
    mapStyle: {
      type: String
    },
    areaCode: {
      type: String,
      default: '3608'
    }
  },
  data () {
    return {
      loading: false,
      loading1: false,
      showLeft: true,
      pageNo: 1,
      pageSize: 100,
      total: 0,
      keyWord: '',
      type: [],
      options: [],
      list: [],
      // 地图相关变量
      map: '',
      pointLng: 115.0196282584,
      pointlat: 27.1058914875,
      point: '',
      marker: '',
      markerIcon: '',
      label: '',
      infoWindow: '',
      styleJson: '',
      videoUrl: '',
      showModal11: false
    }
  },
  components: {
    EZUIKit
  },
  created () {
    switch (this.mapStyle) {
      case 'normal':
        this.styleJson = require('../assets/mapJson/normal.json')
        break
      case 'tea':
        this.styleJson = require('../assets/mapJson/tea.json')
        break
      case 'green':
        this.styleJson = require('../assets/mapJson/green.json')
        break
      default: break
    }
    this.loading = true
    if (this.name === '设备详情') {
      this.getDeviceTypeList()
    }
    this.getList()
    window.devDisplay = this.devDisplay
  },
  methods: {
    /*
      左侧栏相关方法
    */
    /* 获取列表 */
    async getList () {
      this.loading1 = true
      let path = ''
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      if (this.name === '单位详情') {
        path = '/unit/listWeb'
        params.unitName = this.keyWord
      } else if (this.name === '设备详情') {
        path = '/equipment/list'
        params.unitName = this.keyWord
        params.typeListIds = this.type.join(',')
      } else if (this.name === '九小场所') {
        path = '/unit/listWeb'
        params.areaCode = this.areaCode
        params.typeId = 3
        params.unitName = this.keyWord
      } else if (this.name === '重点单位') {
        path = '/unit/listWeb'
        params.areaCode = this.areaCode
        params.typeId = 1
        params.unitName = this.keyWord
      } else if (this.name === '水源') {
        path = '/fireHydrant/list'
        params.areaCode = this.areaCode
        params.keyword = this.keyWord
      } else if (this.name === '摄像头') {
        path = '/equipment/listByInstall'
        params.areaCode = this.areaCode
        params.typeId = 8
        params.unitName = this.keyWord
      } else if (this.name === '建筑物') {
        path = '/building/list'
        params.areaCode = this.areaCode
        params.buildingName = this.keyWord
      }
      const { data: res } = await this.$axios.get(path, { params: params })
      this.loading = false
      this.loading1 = false
      if (res.code !== 200) return this.$message.error(res.msg)
      this.list = res.data.result
      this.total = res.data.total
      if (this.name === '单位详情' || this.name === '九小场所' || this.name === '重点单位' || this.name === '建筑物') {
        this.drawUnit()
      } else if (this.name === '设备详情' || this.name === '摄像头') {
        this.drawDevice()
      } else if (this.name === '水源') {
        this.drawWater()
      }
    },
    /* 获取设备类型 */
    async getDeviceTypeList () {
      const { data: res } = await this.$axios.get('/equipment-type/list')
      if (res.code !== 200) return this.$message.error(res.msg)
      this.options = res.data
    },
    /* 搜索 */
    search () {
      console.log(this.type)
      this.pageNo = 1
      this.getList()
    },
    /* 分页 */
    currentChange (val) {
      this.pageNo = val
      this.getList()
    },
    /* 修改页面大小 */
    changSize (val) {
      console.log(val)
      this.pageNo = 1
      this.pageSize = val
      this.getList()
    },
    /*
      右侧地图相关方法
    */
    handler ({ BMap, map }) {
      this.map = map
      this.point = (lng, lat) => {
        return new BMap.Point(lng, lat)
      }
      this.infoWindow = (content, opts) => {
        return new BMap.InfoWindow(content, opts)
      }
      this.marker = (obj) => {
        return new BMap.Marker(obj)
      }
      this.label = (name, num1, num2) => {
        return new BMap.Label(name, { offset: new BMap.Size(num1, num2) })
      }
      this.markerIcon = (imgUrl, num1, num2) => {
        return new BMap.Icon(imgUrl, new BMap.Size(num1, num2))
      }
      this.selfMarker = (pt, myIcon) => {
        return new BMap.Marker(pt, { icon: myIcon })
      }
      // 地图的初始中心点
      const point = this.point(this.pointLng, this.pointlat)
      this.map.centerAndZoom(point, 16)
      // 默认主题
      this.map.setMapStyle({
        styleJson: this.styleJson
      })
    },
    /* 画出单位的 marker */
    drawUnit () {
      this.clearMarker('unit')
      this.list.forEach((item, index) => {
        if (index === 0) {
          this.pointTo(item, 'localtion')
        }
        const point = this.point(item.lng, item.lat)
        const marker = this.marker(point)
        marker.type = 'unit'
        this.map.addOverlay(marker)
        marker.addEventListener('click', (e) => {
          this.pointTo(item, 'localtion')
        })
      })
    },
    /* 画出设备的 marker */
    drawDevice () {
      this.clearMarker('unit')
      this.list.forEach((item, index) => {
        if (index === 0) {
          this.pointTo(item, 'localtion')
        }
        const point = this.point(item.lng, item.lat)
        const myIcon = this.markerIcon(require('../assets/img/' + item.typeId + '.png'), 50, 50)
        const marker = this.selfMarker(point, myIcon)
        marker.type = 'unit'
        this.map.addOverlay(marker)
        marker.addEventListener('click', (e) => {
          this.drawInfoWindow(item, point)
        })
      })
    },
    /* 画出水源的 marker */
    drawWater () {
      this.clearMarker('unit')
      this.list.forEach((item, index) => {
        if (index === 0) {
          this.pointTo(item, 'localtion')
        }
        const point = this.point(item.lng, item.lat)
        const myIcon = this.markerIcon(require('../assets/img/fireWater.png'), 50, 50)
        const marker = this.selfMarker(point, myIcon)
        marker.type = 'unit'
        this.map.addOverlay(marker)
        marker.addEventListener('click', (e) => {
          this.drawInfoWindow(item)
        })
      })
    },
    /* 信息框 */
    drawInfoWindow (item) {
      let content = ''
      const point = this.point(item.lng, item.lat)
      if (this.name === '单位详情' || this.name === '九小场所' || this.name === '重点单位') {
        content = '<div style="display: flex;align-items: flex-start;justify-content: space-between;">'
        if (item.unitImage !== '') {
          content += `<div style="width: 100px;">
                    <img src="${item.unitImage}" style="width: 100%;height: 100%" />
                  </div>`
        } else {
          content += '<div style="width: 100px;height:100px;background-color: #f5f5f5;color: #999;display: flex;align-items: center;justify-content: center;">暂无图片</div>'
        }
        content += `<div style="padding-left: 10px">
                      <p>地址：${item.areaName}${item.address}</p>
                      <p>负责人：${item.legalName}</p>
                      <p>联系方式：${item.legalPhone}</p>
                      <p>安全等级：${item.monitorLevelName}</p>
                    </div>
                  </div>`
      } else if (this.name === '设备详情' || this.name === '摄像头') {
        const imgSrc = require('../assets/img/' + item.typeId + '.png')
        content = '<div style="display: flex;align-items: flex-start;justify-content: space-between;">'
        if (item.unitImage !== '') {
          content += `<div style="width: 100px;height:100px;">
                    <img src="${imgSrc}" style="width: 100%;height: 100%" />
                  </div>`
        } else {
          content += '<div style="width: 100px;height:100px;background-color: #f5f5f5;color: #999;display: flex;align-items: center;justify-content: center;">暂无图片</div>'
        }
        content += `<div style="padding-left: 10px">
                      <p>设备类型：${item.typeName}</p>
                      <p>地址：${item.address}</p>
                      <p>负责人：${item.legalName}</p>
                      <p>联系方式：${item.contactPhone}</p>`
        if (item.typeName === '视频摄像头' || this.name === '摄像头') {
          content += `<div style="display: flex;justify-content:space-between; aligin-items: center;margin-top: 10px">
                        <input type="button" value="查看" onclick="return devDisplay('${item.videoUrl}')" style="cursor: pointer;background-color: blue;margin-top: 10px;padding: 5px 20px;outline:none;border:none;border-radius: 10px;color: #fff"/>
                      </div>
                    </div>
                  </div>`
        } else {
          content += '</div></div>'
        }
      } else if (this.name === '建筑物') {
        content = '<div style="display: flex;align-items: flex-start;justify-content: space-between;">'
        content += `<div style="padding-left: 10px">
                      <p>所属单位：${item.unitName}</p>
                      <p>地址：${item.areaName}${item.address}</p>
                      <p>负责人：${item.managerName}</p>
                      <p>联系方式：${item.managerName}</p>
                      <p>建筑结构：${item.structureName ? item.structureName : ''}</p>
                    </div>
                  </div>`
      } else if (this.name === '水源') {
        content = '<div style="display: flex;align-items: flex-start;justify-content: space-between;">'
        if (item.imageUrl !== '') {
          content += `<div style="width: 100px;">
                    <img src="${item.imageUrl}" style="width: 100%;height: 100%" />
                  </div>`
        } else {
          content += '<div style="width: 100px;height:100px;background-color: #f5f5f5;color: #999;display: flex;align-items: center;justify-content: center;">暂无图片</div>'
        }
        content += `<div style="padding-left: 10px">
                      <p>地址：${item.location}</p>
                      <p>管网形式：${item.pipeType === 1 ? '环状' : '枝装'}</p>
                      <p>官网直径：${item.width}mm</p>
                      <p>官网压力：${item.pressure}MPa</p>
                      <p>状态：${item.status === 0 ? '良好' : item.status === 1 ? '不可用' : '损坏'}</p>
                    </div>
                  </div>`
      }
      let infoWindow = ''
      if (this.name !== '建筑物' && this.name !== '水源') {
        infoWindow = this.infoWindow(content, { title: item.unitName })
      } else if (this.name === '建筑物') {
        infoWindow = this.infoWindow(content, { title: item.buildingName })
      } else if (this.name === '水源') {
        infoWindow = this.infoWindow(content, { title: '消防栓' })
      }
      this.map.openInfoWindow(infoWindow, point)
    },
    devDisplay (videoUrl) {
      this.videoUrl = videoUrl
      this.showModal11 = true
    },
    /* 清除指定类型的 marker */
    clearMarker (type) {
      const allOverlay = this.map.getOverlays()
      if (allOverlay) {
        allOverlay.forEach(item => {
          if (item.type === type) {
            this.map.removeOverlay(item)
            return false
          }
        })
      }
    },
    /* 跳点 */
    pointTo (item, flag) {
      const point = this.point(item.lng, item.lat)
      this.map.centerAndZoom(point, 18)
      if (flag === 'localtion') {
        this.drawInfoWindow(item, point)
      }
    },
    /* 关闭弹窗 */
    close () {
      this.keyWord = ''
      this.type = []
      this.pageNo = 1
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
i {
  font-size: 18px;
  cursor: pointer;
  color: #333;
}
i:hover {
  color: #0000f3;
}
.container_content {
  width: 100%;
  height: 800px;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: space-between;
  .left_box {
    flex: 1;
    padding: 10px;
    display: flex;
    overflow: hidden;
    border-right: 1px solid #ccc;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    .search_box {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      .title {
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 10px;
        color: #fff;
      }
      > div {
        flex: 4;
        ::v-deep .el-input__inner {
          background-color: rgba(7, 104, 159, 0.15);
          color: #fff;
        }
      }
      > div:last-child {
        flex: 5;
        margin-left: 5px;
      }
    }
    > div:nth-child(2) {
      flex: 10;
      padding: 10px 0;
      overflow: hidden;
      ::v-deep .el-table,
      ::v-deep .el-table__expanded-cell {
        background-color: transparent;
        tr,
        th {
          background-color: transparent;
          color: #fff;
          .el-table__expand-icon {
            color: #fff;
          }
        }
        .el-table--enable-row-hover,
        .hover-row > td,
        tr:hover > td {
          background-color: rgba(7, 104, 159, 0.15) !important;
        }
        .el-table__row--striped > td {
          background-color: rgba(7, 104, 159, 0.15) !important;
        }
      }
    }
    > div:last-child {
      display: flex;
      justify-content: flex-start;
      box-sizing: border-box;
      ::v-deep .el-pagination {
        margin-right: auto;
        color: #fff;
        background-color: transparent;
        .el-pagination__total,
        .el-pagination__jump {
          color: #fff;
        }
        .btn-prev,
        .btn-next {
          background-color: transparent;
          color: #fff;
        }
        .el-pager li {
          background-color: transparent !important;
        }
        .btn-quicknext {
          color: #fff;
        }
      }
    }
  }
  .right_box {
    flex: 3;
    position: relative;
    .bmView,
    .map_box {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .video {
      position: absolute;
      bottom: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 9999 !important;
      border: 1px solid #ccc;
      background-color: #f5f5f5;
    }
    .left_button {
      cursor: pointer;
      position: absolute;
      top: 370px;
      left: -1px;
      bottom: 370px;
      padding: 0 10px;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      z-index: 10;
      border: 1px solid #ccc;
      border-left: none;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
      background-color: #fff;
    }
    .close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      font-weight: bold;
      padding: 5px;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
  }
}
::v-deep  .el-loading-mask {
  background-color: rgba(255, 255, 255, 0.5);
}
::v-deep  .el-pagination {
  padding: 0;
}
</style>
