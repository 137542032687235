<template>
  <span>{{ offlineDeviceTotal }}</span>
</template>

<script>
export default {
  name: "OfflineDeviceTotal",
  props: {
    areaCode: [String, Number]
  },
  data() {
    return {
      offlineDeviceTotal: 0
    }
  },
  watch: {
    areaCode() {
      this.getOfflineDeviceTotal()
    }
  },
  created() {
    this.getOfflineDeviceTotal()
  },
  methods: {
    async getOfflineDeviceTotal() {
      const {data: result} = await this.$axios.get('/equipment/countNoBeatOver2Days', {
        params: {
          areaCode: this.areaCode
        }
      })
      this.offlineDeviceTotal = result.data.num
    },
  }
}
</script>
