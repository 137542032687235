<template>
  <div>
    <div id="myEcharts" ref="chart"></div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {
    }
  },
  mounted () {
    this.drawEcharts()
  },
  watch: {
    data () {
      this.drawEcharts()
    }
  },
  methods: {
    drawEcharts () {
      let total = 0
      this.data.forEach(item => {
        // if (item.typeName === '九小场所') {
        //   item.total = 1273
        // }
        total += item.total
      })

      // 气泡颜色数组
      const color = [
        '#FFB600', '#886CFF', '#0084FF',
        '#4CB690', '#58B458', '#6C6C6C',
        '#F56161', '#FC754C', '#5F5EEC'
      ]
      // 气泡颜色备份
      let bakeColor = [...color]
      // 气泡数据
      const bubbleData = []
      // 气泡基础大小
      let basicSize = 70
      // 节点之间的斥力因子,值越大,气泡间距越大
      let repulsion = 100
      // 根据气泡数量配置基础大小和斥力因子（以实际情况进行适当调整，使气泡合理分布）
      if (this.data.length >= 5 && this.data.length < 10) {
        basicSize = 60
        repulsion = 150
      }
      if (this.data.length >= 10 && this.data.length < 20) {
        basicSize = 40
        repulsion = 150
      } else if (this.data.length >= 20) {
        basicSize = 30
        repulsion = 75
      }

      // 填充气泡数据数组bubbleData
      for (const item of this.data) {
        // 确保气泡数据条数少于或等于气泡颜色数组大小时，气泡颜色不重复
        if (!bakeColor.length) bakeColor = [...color]
        const colorSet = new Set(bakeColor)
        const curIndex = Math.round(Math.random() * (colorSet.size - 1))
        const curColor = bakeColor[curIndex]
        colorSet.delete(curColor)
        bakeColor = [...colorSet]
        // 气泡大小设置
        let size = (item.total * basicSize * 2) / total
        if (size < basicSize) size = basicSize

        bubbleData.push({
          name: `${item.typeName}:${item.total}`,
          value: item.total,
          symbolSize: size,
          draggable: true,
          itemStyle: {
            normal: { color: curColor }
          }
        })
      }
      const bubbleOptions = {
        backgroundColor: 'transparent',
        animationEasingUpdate: 'bounceIn',
        series: [{
          type: 'graph',
          layout: 'force',
          force: {
            repulsion: repulsion,
            edgeLength: 10
          },
          itemStyle: {
            shadowColor: '#fff',
            shadowBlur: 6
          },
          // 是否开启鼠标缩放和平移漫游
          roam: false,
          label: { normal: { show: true } },
          data: bubbleData
        }]
      }
      // 基于准备好的dom，初始化echarts实例
      this.$nextTick(() => {
        const dome = this.$refs.chart
        const myChart = this.$echarts.init(dome)
        // 指定饼图中显示的数据格式
        // 绘制图表
        myChart.setOption(bubbleOptions)
        window.addEventListener('resize', () => { myChart.resize() })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#myEcharts {
  width: 100%;
  height: 100%;
}
</style>
